import {getFieldList, randomAmount, randomArrayItem, randomDate} from "./format";
import faker from "faker/locale/en_US";
import {
    accountMaskValidator,
    bankAccountValidator,
    bankRoutingNumberValidator,
    emailValidator,
    ownershipValidator,
    phoneNumberValidator,
    ssn4Validator,
    ssnValidator,
    stateValidator,
    zipValidator
} from "./validators";

export const VERSION = '0.1.1:2023-07-27'

export const mainTheme = {
    palette: {
        primary: {
            main: '#07c0ca',
            contrastText: '#ffffff'
        }
    }
}

// https://github.com/reduxjs/redux-devtools/tree/75322b15ee7ba03fddf10ac3399881e302848874/src/react/themes
export const jsonTreeTheme = {
    scheme: 'hopscotch',
    base00: '#322931',
    base01: '#433b42',
    base02: '#5c545b',
    base03: '#797379',
    base04: '#989498',
    base05: '#b9b5b8',
    base06: '#d5d3d5',
    base07: '#ffffff',
    base08: '#dd464c',
    base09: '#fd8b19',
    base0A: '#fdcc59',
    base0B: '#8fc13e',
    base0C: '#149b93',
    base0D: '#1290bf',
    base0E: '#c85e7c',
    base0F: '#b33508'
};

export const userFormFields = {
    "phoneNumberEncrypted": {
        label: 'Phone Number',
        validator: phoneNumberValidator,
        required: true,
        type: 'tel',
        autoFill: () => randomArrayItem(['+15555555511', '+15555555522', '+15555555533']),
        initValue: '',
        order: 1
    },
    "emailEncrypted": {
        label: 'Email',
        validator: emailValidator,
        required: true,
        type: 'email',
        autoFill: () => randomArrayItem(['test+user1@wisetack.com', 'test+user2@wisetack.com', 'test+user3@wisetack.com']),
        initValue: '',
        order: 2
    },
    "firstNameEncrypted": {
        label: 'First Name',
        required: true,
        autoFill: faker.name.firstName,
        initValue: '',
        order: 3
    },
    "lastNameEncrypted": {
        label: 'Last Name',
        required: true,
        autoFill: faker.name.lastName,
        initValue: '',
        order: 4
    },
    "userRole": {
        label: 'Role',
        type: 'select',
        data: [
            {id: "STANDARD"},
            {id: "ADMIN"}
        ],
        initValue: 'STANDARD',
        order: 5
    },
    "blocked": {
        label: 'Blocked',
        type: 'switch',
        initValue: false,
        order: 6
    }
}
export const userFormFieldList = getFieldList(userFormFields)

const pricingGrids = [
]

const mdrGrids = [
]

const verticals = [
]

const products = [
    {
        id: 'product1'
    },
    {
        id: 'product2'
    }
]

export const accountSubTypes = [
    {
        id: 'SAVINGS'
    },
    {
        id: 'CHECKING'
    }
]

export const merchantPortalTransactionEnabled = [
    {
        id: 'FROM ACCOUNT'
    },
    {
        id: 'FALSE'
    },
    {
        id: 'TRUE'
    }
]

export const partnerFormFields = {
    "name": {
        label: 'Name',
        initValue: '',
        order: 1
    },
    "alias": {
        label: 'Alias',
        initValue: '',
        order: 2
    },
    "financialKB": {
        label: 'Financial KB',
        type: 'select',
        data: products,
        initValue: '',
        order: 3
    },
    "defaultVertical": {
        label: 'Default Vertical',
        type: 'select',
        data: verticals,
        initValue: '',
        order: 4
    },
    "maxPayoutsNumber": {
        label: 'Max Phased Payouts Number',
        initValue: '',
        order: 5
    },
    "callbackUrl": {
        label: 'Callback URL',
        initValue: '',
        order: 6
    },
    "keyEncrypted": {
        label: 'Encrypted Key (Vault token)',
        initValue: '',
        order: 7
    },
    "blocked": {
        label: 'Blocked',
        type: 'switch',
        initValue: false,
        order: 8
    },
    "merchantPortalTransactionEnabled": {
        label: 'Send Transactions MTP',
        type: 'switch',
        initValue: false,
        order: 9
    },
    "settlementDelay": {
        label: 'Settlement delay (days)',
        type: 'number',
        order: 10
    }
}
export const partnerFormFieldList = getFieldList(partnerFormFields)

export const ficoFloorCreateFormFields = {
    "vertical": {
        label: 'Vertical',
        initValue: '',
        order: 1
    },
    "value": {
        label: 'FICO score',
        initValue: '',
        order: 2
    }
}

export const ficoFloorCreateFormFieldsList = getFieldList(ficoFloorCreateFormFields)

export const ficoFloorUpdateFormFields = {
    "value": {
        label: 'FICO score',
        initValue: '',
        order: 1
    }
}

export const ficoFloorUpdateFormFieldsList = getFieldList(ficoFloorUpdateFormFields)

export const partnerAllocationConfigFormFields = {
    "ratio": {
        label: 'Randomized Ratio',
        initValue: '',
        order: 1
    }
}
export const partnerAllocationConfigFormFieldList = getFieldList(partnerAllocationConfigFormFields)

export const merchantFormFields = {
    "businessLegalName": {
        label: 'Business Legal Name',
        required: true,
        initValue: '',
        order: 1
    },
    "doingBusinessAsEncrypted": {
        label: 'Doing Business As',
        initValue: '',
        order: 2
    },
    "phoneNumberEncrypted": {
        label: 'Phone Number',
        validator: phoneNumberValidator,
        required: true,
        initValue: '',
        order: 3
    },
    "emailEncrypted": {
        label: 'Email',
        validator: emailValidator,
        required: true,
        initValue: '',
        order: 4
    },
    "industry": {
        label: 'Industry',
        initValue: '',
        order: 5
    },
    "industryProvidedByMerchant": {
        label: 'Industry Provided By Merchant',
        initValue: '',
        order: 6
    },
    "zip": {
        label: 'Zip',
        validator: zipValidator,
        required: true,
        initValue: '',
        order: 7
    },
    "state": {
        label: 'State',
        validator: stateValidator,
        required: true,
        initValue: '',
        order: 8
    },
    "city": {
        label: 'City',
        required: true,
        initValue: '',
        order: 9
    },
    "businessAddressEncrypted": {
        label: 'Business Address',
        required: true,
        initValue: '',
        order: 10
    },
    "bankRoutingNumber": {
        label: 'Bank Routing Number',
        validator: bankRoutingNumberValidator,
        required: true,
        initValue: '',
        order: 11
    },
    "paymentAccountMask": {
        label: 'Bank Account Mask',
        validator: accountMaskValidator,
        required: true,
        initValue: '',
        order: 12
    },
    "bankAccountEncrypted": {
        label: 'Bank Account Number',
        validator: bankAccountValidator,
        required: true,
        initValue: '',
        order: 13
    },
    "bankAccountNameEncrypted": {
        label: 'Bank Account Name',
        initValue: '',
        order: 14
    },
    "bankAccountSubType": {
        label: 'Bank Account SubType',
        type: 'select',
        data: accountSubTypes,
        required: true,
        initValue: '',
        order: 15
    },
    "defaultVertical": {
        label: 'Vertical',
        type: 'select',
        data: verticals,
        initValue: '',
        order: 16
    },
    "defaultPricingGrid": {
        label: 'Pricing Grid',
        type: 'select',
        data: pricingGrids,
        initValue: '',
        order: 17
    },
    "defaultMdrGrid": {
        label: 'MDR Grid',
        type: 'select',
        data: mdrGrids,
        initValue: '',
        order: 18
    },
    "ttmSales": {
        label: 'TTM Sales',
        initValue: '',
        order: 19
    },
    "enrollmentDate": {
        label: 'Enrollment Date',
        type: 'date',
        initValue: null,
        order: 20
    },
    "externalId": {
        label: 'External ID',
        initValue: '',
        order: 21
    },
    "alloyEntityToken": {
        label: 'Alloy Entity Token',
        initValue: '',
        order: 22
    },
    "merchantPortalTransactionEnabled": {
        label: 'Send Transactions MTP',
        type: 'select',
        data: merchantPortalTransactionEnabled,
        required: true,
        initValue: '',
        order: 23
    },
    "maxPayoutsNumber": {
        label: 'Max Phased Payouts Number',
        initValue: '',
        order: 24
    }
}
export const merchantFormFieldList = getFieldList(merchantFormFields)

let ssn4 = null;
let ssn = null;

const randomSSN4 = () => {
    ssn4 = randomAmount(1111, 9999).toString();
    return ssn4;
}

const randomSSN = () => {
    if (ssn4) {
        ssn = randomAmount(11111, 99999).toString() + ssn4;
        ssn4 = null;
        return ssn;
    }
    ssn = randomAmount(111111111, 999999999).toString();
    return ssn;
}

export const transactionFormFields = {
    "userId" : {
        label: 'User Id',
        initValue: '',
        order: 1
    },
    "mobileNumber": {
        label: 'Borrower Mobile Number',
        initValue: '',
        autoFill: () => randomArrayItem(['+15555555551', '+15555555552', '+15555555553']),
        order: 2
    },
    "transactionAmount": {
        label: 'Transaction Amount',
        initValue: '',
        autoFill: () => randomAmount(500, 15000),
        order: 3
    },
    "transactionPurpose": {
        label: 'Transaction Purpose',
        initValue: '',
        autoFill: (val) => val === '*' ? 'balanceThreshold=150#bankName=Chase#plaidError=Plaid error generated for testing. (only for Chase bank)#fraud_check_result=MANUAL_REVIEW' : faker.commerce.productName(),
        order: 4
    },
    "serviceCompletedOn": {
        label: 'Service Completed On',
        initValue: null,
        autoFill: new Date(),
        type: 'date',
        order: 5
    },
    "callbackURL": {
        label: 'Callback URL',
        initValue: '',
        order: 6
    },
    "loanServiceProvider": {
        label: 'Loan Service Provider',
        type: 'select',
        data: [
            {id: 'UNDEFINED'},
            {id: 'PEACH'},
            {id: 'SCRATCH'},
        ],
        initValue: '',
        order: 7
    },
    "firstName": {
        label: 'First Name',
        initValue: '',
        autoFill: faker.name.firstName,
        order: 8
    },
    "lastName": {
        label: 'Last Name',
        initValue: '',
        autoFill: faker.name.lastName,
        order: 9
    },
    "email": {
        label: 'Email',
        initValue: '',
        autoFill: 'test+user@wisetack.com',
        order: 10
    },
    "dob": {
        label: 'DOB',
        initValue: null,
        type: 'date',
        autoFill: () => randomDate(new Date('1939-01-01'), new Date('2000-01-01')),
        order: 11
    },
    "ssn4": {
        label: 'SSN4',
        initValue: '',
        autoFill: randomSSN4,
        order: 12
    },
    "ssn": {
        label: 'SSN',
        initValue: '',
        autoFill: randomSSN,
        order: 13
    },
    "streetAddress1": {
        label: 'Street Address 1',
        initValue: '',
        autoFill: faker.address.streetAddress,
        order: 14
    },
    "streetAddress2": {
        label: 'Street Address 2',
        initValue: '',
        autoFill: faker.address.streetAddress,
        order: 15
    },
    "city": {
        label: 'City',
        initValue: '',
        autoFill: faker.address.city,
        order: 16
    },
    "stateCode": {
        label: 'State Code',
        initValue: '',
        autoFill: 'CA',
        order: 17
    },
    "zip": {
        label: 'Zip',
        initValue: '',
        autoFill: () => randomArrayItem(['90001', '90002', '90003', '90004', '90005']),
        order: 18
    },
    "employer": {
        label: 'Employer',
        initValue: '',
        autoFill: faker.company.companyName,
        order: 19
    },
    "annualIncomeBeforeTaxes": {
        label: 'Annual Income Before Taxes',
        initValue: '',
        autoFill: () => randomAmount(5000, 200000),
        order: 20
    },
    "settlementDelay": {
        label: 'Settlement Delay',
        type: 'switch',
        initValue: false,
        order: 21
    },
    "ignorePayouts": {
        label: 'Ignore Payouts',
        type: 'switch',
        initValue: false,
        order: 22
    }
};
export const transactionFieldList = getFieldList(transactionFormFields)

export const initYears = () => {
    let years = []
    let year = new Date().getFullYear()
    while (year > 2018) {
        years.push({
            id: String(year)
        })
        year --
    }
    return years
}

export const initMonths = [
    {id: '01', name: 'January'},
    {id: '02', name: 'February'},
    {id: '03', name: 'March'},
    {id: '04', name: 'April'},
    {id: '05', name: 'May'},
    {id: '06', name: 'June'},
    {id: '07', name: 'Jule'},
    {id: '08', name: 'August'},
    {id: '09', name: 'September'},
    {id: '10', name: 'October'},
    {id: '11', name: 'November'},
    {id: '12', name: 'December'}
]

export const loanStatuses = [
    {id: "PENDING"},
    {id: "EXPIRED"},
    {id: "OFFER_CONVERTED"},
    {id: "PAYOUTS_CONVERTED"},
    {id: "DOCUMENTS_SIGNED"},
    {id: "OFFER_AVAILABLE"},
    {id: "ACCOUNT_LINKED"},
    {id: "CONDITIONAL_APPROVAL"},
    {id: "CONDITIONAL_APPROVAL_ACCOUNT_LINKED"},
    {id: "REJECTED"},
    {id: "DECLINED"},
    {id: "CANCELED"},
    {id: "SETTLED"},
    {id: "PAYOUTS_SETTLED"}
]

export const loanInfoFields = {
    "loanApplicationId": {
        label: 'Loan Application ID',
        initValue: '',
        order: 1
    },
    "dataTypes": {
        label: 'Data Types',
        type: 'select',
        data: [
            {
                id: 'LOAN'
            },
            {
                id: 'PAYOUTS'
            },
            {
                id: 'OFFERS'
            },
            {
                id: 'PLAID'
            },
            {
                id: 'ALLOY_RAW'
            },
            {
                id: 'PAYMENT'
            },
            {
                id: 'REFUNDS'
            },
            {
                id: 'ALLOCATIONS'
            },
            {
                id: 'PARTNER_OUTCOMES'
            },
            {
                id: 'NOTIFICATIONS'
            },
            {
                id: 'DECRYPT_NOTIFICATIONS'
            }
        ],
        multiple: true,
        initValue: ['LOAN', 'PAYOUTS', 'OFFERS', 'REFUNDS', 'ALLOCATIONS', 'PARTNER_OUTCOMES', 'DECRYPT_NOTIFICATIONS'],
        order: 2
    },
}
export const loanInfoFieldList = getFieldList(loanInfoFields)

export const representativeFormFields = {
    "id": {
        label: 'Representative ID',
        initValue: '',
    },
    "firstName": {
        label: 'First Name',
        required: true,
        initValue: '',
        autoFill: faker.name.firstName,
        order: 1
    },
    "lastName": {
        label: 'Last Name',
        required: true,
        initValue: '',
        autoFill: faker.name.lastName,
        order: 2
    },
    "mobileNumber": {
        label: 'Mobile Number',
        validator: phoneNumberValidator,
        required: true,
        initValue: '',
        autoFill: () => randomArrayItem(['+15555555551', '+15555555552', '+15555555553']),
        order: 3
    },
    "email": {
        label: 'Email',
        validator: emailValidator,
        required: true,
        initValue: '',
        autoFill: 'test+user@wisetack.com',
        order: 4
    },
    "homeAddress": {
        label: 'Street Address',
        required: true,
        initValue: '',
        autoFill: faker.address.streetAddress,
        order: 5
    },
    "city": {
        label: 'City',
        required: true,
        initValue: '',
        autoFill: faker.address.city,
        order: 6
    },
    "state": {
        label: 'State Code',
        validator: stateValidator,
        required: true,
        initValue: '',
        autoFill: 'CA',
        order: 7
    },
    "zip": {
        label: 'Zip',
        validator: zipValidator,
        required: true,
        initValue: '',
        autoFill: () => randomArrayItem(['90001', '90002', '90003', '90004', '90005']),
        order: 8
    },
    "dob": {
        label: 'DOB',
        required: true,
        initValue: null,
        type: 'date',
        autoFill: () => randomDate(new Date('1939-01-01'), new Date('2000-01-01')),
        order: 9
    },
    "ssn4": {
        label: 'SSN4',
        validator: ssn4Validator,
        required: true,
        initValue: '',
        autoFill: randomSSN4,
        order: 10
    },
    "ssn": {
        label: 'SSN',
        validator: ssnValidator,
        required: true,
        initValue: '',
        autoFill: randomSSN,
        order: 11
    },
    "executive": {
        label: 'Executive',
        type: 'switch',
        initValue: false,
        order: 12
    },
    "ownership": {
        label: 'Ownership',
        validator: ownershipValidator,
        required: true,
        initValue: '',
        autoFill: () => randomAmount(20, 100) + '%',
        order: 13
    },
    "alloyEntityToken": {
        label: 'Alloy Entity Token',
        initValue: '',
        order: 14
    },
    "toRemove": {
        label: 'Remove',
        type: 'switch',
        initValue: false,
        order: 15
    }
}
export const representativeFieldList = getFieldList(representativeFormFields)
