import 'date-fns';
import React from 'react';

import Grid from "@material-ui/core/Grid";

import ExPanel from "./ExPanel";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core/styles";
import SubmitButton from "./SubmitButton";
import useApiRequest from "../hooks/useApiRequest";
import ErrorMessage from "./ErrorMessage";

const useStyles = makeStyles((theme) => ({
    input: {
        display: 'none',
    },
}));

export default function UploadHatchNachaFilesPanel({submitRequest}) {
    const classes = useStyles();
    const [, , requestError, newRequest] = useApiRequest();

    function handleSubmit(event) {
        event.preventDefault();
        submitRequest('upload-hatch-files-to-s3', newRequest());
    }

    return (
        <ExPanel title="Trigger Hatch NACHA files upload">
                <Grid item xs={12}>
                    <form className={classes.root}>
                        <Box display="flex" alignItems="center" flexDirection="column">
                            <SubmitButton onClick={handleSubmit} />
                            <ErrorMessage errorMessage={requestError}/>
                        </Box>
                    </form>
                </Grid>
        </ExPanel>
    )
}
