import {
    formatBoardingFilesInfo,
    formatBorrowerCreditFile,
    formatBorrowerProfile,
    formatConsoleEffectivePermission,
    formatConsolePermissions,
    formatLoanInfo,
    formatLoanList,
    formatLockoutInfo,
    formatMerchantsInfo,
    formatPaymentsInfo,
    formatPayoutInfo,
    formatPayoutsInfo,
    formatPrequalInfo,
    formatRecentMerchants,
    formatRefunds,
    formatReportsInfo,
    formatTransactionsInfo,
    updateRefundsList
} from "../../utils/format";
import {get} from "lodash-es"

import {setCognitoCredentials} from "../actions/aws";
import {forgetUser} from "../../utils/localStorage";

const initState = {
    drawerOpened: false,
    isLoading: false,
    errorMessage: "",
    vaultToken: "",
    valueToEncrypt: "",
    apiRequests: {},
    formsData: {},
    usersInfo: {users: []},
    partnersInfo: {},
    dataLakeResponse: {},
    logGroupsResponse: {},
    merchantApiResponse: {},
    templates: {templateIds: []},
    templateData: { id: "", templateString: "", uniqueTemplateVariables: [] },
    templateViewerHTML: { subject: "", body: ""},
    featureConfig: { entityId: "", featureConfigData: ""}
}

const convertSettings = (settings) => {
    let pricingGrids = []
    let mdrGrids = []
    let verticals = []
    let loanStatuses = []
    let payoutStatuses = []
    let payoutQueryModes = []
    let supportedPermissions = []
    if (settings.metaData) {
        if (settings.metaData.verticals) {
            verticals = settings.metaData.verticals.map(item => ({id: item}))
        }
        if (settings.metaData.pricingGrids) {
            pricingGrids = settings.metaData.pricingGrids.map(item => ({id: item}))
        }
        if (settings.metaData.mdrGrids) {
            mdrGrids = settings.metaData.mdrGrids.map(item => ({id: item}))
        }
        if (settings.metaData.loanStatuses) {
            loanStatuses = settings.metaData.loanStatuses.map(item => ({id: item}))
        }
        if (settings.metaData.payoutStatuses) {
            payoutStatuses = settings.metaData.payoutStatuses.map(item => ({id: item}))
        }
        if (settings.metaData.payoutQueryModes) {
            payoutQueryModes = settings.metaData.payoutQueryModes.map(item => ({id: item}))
        }
        if (settings.metaData.supportedPermissions) {
            supportedPermissions = Object.entries(settings.metaData.supportedPermissions)
                .map(([k, v]) => ({id: k, name: k + ' | ' + v}))
                .sort((a, b) => a.id.localeCompare(b.id))
        }
    }
    return {
        ...settings,
        metaData: {
            pricingGrids,
            mdrGrids,
            verticals,
            loanStatuses,
            payoutStatuses,
            payoutQueryModes,
            supportedPermissions
        }
    };
}

const updateConsolePermissionList = (existingData, updatedData) => {
    if (!updatedData) {
        return existingData;
    }
    const updatedItem = get(formatConsolePermissions(updatedData), 'permissionList[0]')
    if (!updatedItem) {
        return existingData;
    }
    const {items} = updateItemList(
        existingData.permissionList,
        updatedItem,
        updatedData.deleted,
        (a,b) => a.subjectId === b.subjectId
    );
    return {permissionList: items};
}

const updateUsersInfo = (usersInfo, updatedUser, remove) => {
    const {items} = updateItemList(
        usersInfo.users,
        updatedUser,
        remove,
        (a,b) => a.userId === b.userId && a.merchantId === b.merchantId
    );
    return {users: items};
}

const updatePartnersInfo = (partnersInfo, updatedPartner, remove) => {
    const {items} = updateItemList(
        partnersInfo.accounts,
        updatedPartner,
        remove,
        (a,b) => a.id === b.id
    );
    return {accounts: items};
}

const updatePayoutsInfo = (payoutsInfo, updatedPayout, remove) => {
    if (!updatedPayout) {
        return payoutsInfo;
    }
    const {items} = updateItemList(
        payoutsInfo.payouts,
        updatedPayout,
        remove,
        (a,b) => a.id === b.id
    );
    return {payouts: items};
}

const updateDynamoDBRecords = (records, updatedRecords, remove) => {
    if (!updatedRecords || updatedRecords.length === 0) {
        return records;
    }
    const record = updatedRecords[0];
    const {items} = updateItemList(
        records,
        record,
        remove,
        (a,b) => a.hashKey === b.hashKey && a.rangeKey === b.rangeKey
    );
    return items;
}

const updateLockoutInfo = (lockoutInfo, updatedInfo) => {
    if (!lockoutInfo || !lockoutInfo.lockouts) {
        return lockoutInfo;
    }
    let updatedItem = null;
    if (updatedInfo && updatedInfo.lockouts && updatedInfo.lockouts.length === 1) {
        updatedInfo = formatLockoutInfo(updatedInfo);
        updatedItem = updatedInfo.lockouts[0];
    }
    if (updatedItem) {
        const {items} = updateItemList(
            lockoutInfo.lockouts,
            updatedItem,
            false,
            (a,b) => a.id === b.id
        );
        return {lockouts: items}
    }
    return lockoutInfo;
}

const updateMerchant = (merchantsInfo, updatedInfo, remove) => {
    let updatedItem = null;
    let items = null;
    let merge = false;
    if (updatedInfo && updatedInfo.merchants && updatedInfo.merchants.length === 1) {
        updatedInfo = formatMerchantsInfo(updatedInfo);
        updatedItem = updatedInfo.merchants[0];
    }
    if (updatedInfo && updatedInfo.id) {
        updatedItem = updatedInfo;
        merge = true;
    }
    if (updatedItem) {
        const result = updateItemList(
            merchantsInfo.merchants,
            updatedItem,
            remove,
            (a,b) => a.id === b.id,
            merge
        );
        items = result.items;
        updatedItem = result.updatedItem;
    }
    if (items && updatedItem) {
        return {merchantsInfo: {merchants: items}, updatedMerchant: updatedItem}
    }
    return {merchantsInfo}
}

const updateLoanLockoutInfo = (loanInfo, loanLockoutInfo) => {
    if(loanInfo && loanInfo.loanApplication && loanLockoutInfo && loanLockoutInfo.loanApplication && loanLockoutInfo.loanApplication.id === loanInfo.loanApplication.id) {
        loanInfo.loanApplication.authPinAttempts = loanLockoutInfo.loanApplication.authPinAttempts;
    }
    return loanInfo
}

const sortIndustryList = (industryList) => {
    if (!industryList || industryList.length === 1) {
        return industryList
    }
    return industryList.sort((a, b) => {
        if (a.name > b.name) {
            return 1;
        }
        if (a.name < b.name) {
            return -1;
        }
        return 0;
    })
}

const updateItemList = (itemList, updatedItem, remove, comparator, merge) => {
    let items = [];
    let updatedItemFound = false;
    if (itemList) {
        itemList.forEach(item => {
            if(comparator(updatedItem, item)) {
                updatedItemFound = true;
                if (!remove) {
                    if (merge) {
                        updatedItem = {
                            ...item,
                            ...updatedItem
                        }
                    }
                    items.push(updatedItem);
                }
            }
            else {
                items.push(item);
            }
        })
    }
    if (!updatedItemFound && !remove) {
        items.push(updatedItem);
    }
    return {items, updatedItem};
}

const updateFicoFloors = (stateFicoFloors, updatedFicoFloors) => {

    if (!updatedFicoFloors) {
        return stateFicoFloors;
    }

    let updated = {};

    updatedFicoFloors.forEach(ficoFloor =>
        updated = updateItemList(stateFicoFloors,
            ficoFloor,
            false,
            (a, b) => a.vertical === b.vertical && a.value === b.value,
            true
        )
    )

    return updated.items;
}

const consoleReducer = (state = initState, action) => {
    switch (action.type) {
        case 'create-fico-floor':
            return {
                ...state,
                ficoFloors: updateFicoFloors(state.ficoFloors, action.payload.response.ficoFloors),
                ficoFloorCreateRequestId: action.payload.requestId
            }
        case 'update-fico-floor':
            return {
                ...state,
                ficoFloors: updateFicoFloors(state.ficoFloors, action.payload.response.ficoFloors),
                ficoFloorUpdateRequestId: action.payload.requestId
            }
        case "fico-floors-list":
            return {
                ...state,
                ficoFloors: action.payload.response.ficoFloors,
            };
        case "drawer-opened":
            return {
                ...state,
                drawerOpened: action.payload
            };
        case "api-request-started":
            const apiRequests = {}
            const formsData = {}
            if (action.payload.requestId) {
                apiRequests[action.payload.requestId] = action.payload.requestType || true
            }
            if (action.payload.requestType) {
                formsData[action.payload.requestType] = action.payload.requestData || {}
            }
            return {
                ...state,
                apiRequests: {
                    ...state.apiRequests,
                    ...apiRequests
                },
                formsData: {
                    ...state.formsData,
                    ...formsData
                }
            }
        case "security-token-expired": {
            return {
                ...state,
                securityTokenExpired: action.payload
            };
        }
        case "api-request-finished":
            const requests = {...state.apiRequests}
            if (action.payload.requestId) {
                delete requests[action.payload.requestId]
            }
            return {
                ...state,
                apiRequests: requests
            }
        case "google-sign-in":
        case "cognito-sign-in":
            return {
                ...state,
                cognitoSignIn: action.payload.response,
                cognitoSignInRequestId: action.payload.requestId
            };
        case "refresh-cognito-session":
            return {
                ...state,
                cognitoSignIn: action.payload.response,
                cognitoSignInRequestId: action.payload.requestId
            };
        case "metadata": {
            return {
                ...state,
                settings: convertSettings({
                    ...state.settings,
                    metaData: action.payload.response
                }),
                metadataRequestId: action.payload.requestId
            };
        }
        case "vault-encrypt":
            return {
                ...state,
                vaultToken: action.payload.response.vaultToken,
                valueToEncrypt: action.payload.response.valueToEncrypt,
                encryptRequestId: action.payload.requestId
            };
        case "vault-decrypt":
            return {
                ...state,
                tokenToDecrypt: action.payload.response.tokenToDecrypt,
                decryptedValue: action.payload.response.decryptedValue,
                decryptRequestId: action.payload.requestId
            };
        case "vault-get-config":
            return {
                ...state,
                vaultConfig: action.payload.response,
                vaultGetConfigRequestId: action.payload.requestId
            }
        case "payment-link":
            return {
                ...state,
                paymentLink: action.payload.response.paymentLink,
                transactionId: action.payload.response.transactionId,
                paymentLinkRequestId: action.payload.requestId
            }
        case "create-transaction":
            return {
                ...state,
                transactionInfo: action.payload.response,
                createTransactionRequestId: action.payload.requestId
            }
        case "create-signup":
            return {
                ...state,
                signupLink: action.payload.response.signupLink,
                merchantId: action.payload.response.merchantId,
                createSignupRequestId: action.payload.requestId
            }
        case "loan-info":
            return {
                ...state,
                loanInfo: formatLoanInfo(action.payload.response),
                loanInfoRequestId: action.payload.requestId
            }
        case "partners-info":
            return {
                ...state,
                partnersInfo: action.payload.response,
                partnersInfoRequestId: action.payload.requestId
            }
        case "partner-allocation-configs":
            return {
                ...state,
                partnersAllocationConfigInfo: action.payload.response,
                partnersAllocationConfigInfoRequestId: action.payload.requestId
            }
        case "update-partner":
            return {
                ...state,
                partnersInfo: updatePartnersInfo(state.partnersInfo, action.payload.response),
                updatePartnerRequestId: action.payload.requestId
            }
        case "delete-partner":
            return {
                ...state,
                deletePartnerRequestId: action.payload.requestId
            }
        case "merchants-info":
            return {
                ...state,
                merchantsInfo: formatMerchantsInfo(action.payload.response),
                updatedMerchant: null,
                merchantsInfoRequestId: action.payload.requestId
            }
        case "industry-list":
        case "industry-update":
        case "industry-remove":
            return {
                ...state,
                industryList: sortIndustryList(action.payload.response.industryList),
                industryListRequestId: action.payload.requestId
            }
        case "prequals-list":
            return {
                ...state,
                prequalInfo: formatPrequalInfo(action.payload.response),
                prequalListRequestId: action.payload.requestId
            }
        case "refunds-list":
            return {
                ...state,
                refunds: formatRefunds(action.payload.response),
                refundsListRequestId: action.payload.requestId
            }
        case "delete-refund":
            return {
                ...state,
                refunds: updateRefundsList(state.refunds, action.payload.response),
                refundDeleteRequestId: action.payload.requestId
            }
        case "transactions-info":
            return {
                ...state,
                transactionsInfo: formatTransactionsInfo(action.payload.response),
                transactionsInfoRequestId: action.payload.requestId
            }
        case "decrypt-report-file": {
            return {
                ...state,
                reportFileInfo: action.payload.response,
                reportFileInfoRequestId: action.payload.requestId
            }
        }
        case "signup-info":
            return {
                ...state,
                signupInfo: action.payload.response.signup,
                signupInfoRequestId: action.payload.requestId
            }
        case "signup-list-info":
            return {
                ...state,
                signupList: action.payload.response.signups,
                signupListRequestId: action.payload.requestId
            }
        case "reload-merchant":
            return {
                ...state,
                ...updateMerchant(state.merchantsInfo, action.payload.response),
                merchantsInfoRequestId: action.payload.requestId
            }
        case "update-merchant":
            return {
                ...state,
                ...updateMerchant(state.merchantsInfo, action.payload.response),
                updateMerchantRequestId: action.payload.requestId
            }
        case "review-merchant":
            return {
                ...state,
                ...updateMerchant(state.merchantsInfo, action.payload.response),
                reviewMerchantRequestId: action.payload.requestId
            }
        case "review-merchant-lambda":
            return {
                ...state,
                reviewMerchantResult: action.payload.response,
                reviewMerchantRequestId: action.payload.requestId
            }
        case "users-info":
            return {
                ...state,
                usersInfo: action.payload.response,
                usersInfoRequestId: action.payload.requestId
            }
        case "payouts-list":
            return {
                ...state,
                payoutsInfo: formatPayoutsInfo(action.payload.response),
                payoutsInfoRequestId: action.payload.requestId
            }
        case "payout-cancel":
            return {
                ...state,
                payoutsInfo: updatePayoutsInfo(state.payoutsInfo, formatPayoutInfo(action.payload.response.payout)),
                payoutCancelRequestId: action.payload.requestId
            }
        case "payout-hold":
            return {
                ...state,
                payoutsInfo: updatePayoutsInfo(state.payoutsInfo, formatPayoutInfo(action.payload.response.payout)),
                payoutHoldRequestId: action.payload.requestId
            }
        case "payments-list":
            return {
                ...state,
                paymentsInfo: formatPaymentsInfo(action.payload.response),
                paymentsInfoRequestId: action.payload.requestId
            }
        case "boarding-files-list":
            return {
                ...state,
                boardingFilesInfo: formatBoardingFilesInfo(action.payload.response),
                boardingFilesInfoRequestId: action.payload.requestId
            }
        case "report-files-list":
            return {
                ...state,
                reportsInfo: formatReportsInfo(action.payload.response),
                reportsInfoRequestId: action.payload.requestId
            }
        case "users-lockout-info":
            return {
                ...state,
                usersLockoutInfo: formatLockoutInfo(action.payload.response),
                usersLockoutInfoRequestId: action.payload.requestId
            }
        case "users-lockout-update":
            return {
                ...state,
                usersLockoutInfo: updateLockoutInfo(state.usersLockoutInfo, action.payload.response),
                usersLockoutUpdateRequestId: action.payload.requestId
            }
        case "loan-lockout-update":
            return {
                ...state,
                loanInfo: updateLoanLockoutInfo(state.loanInfo, action.payload.response),
                loanLockoutUpdateRequestId: action.payload.requestId
            }
        case "loan-hold": {
            return {
                ...state,
                loanInfo: formatLoanInfo(action.payload.response),
                loanHoldRequestId: action.payload.requestId
            }
        }
        case "update-user":
            return {
                ...state,
                usersInfo: updateUsersInfo(state.usersInfo, action.payload.response),
                updateUserRequestId: action.payload.requestId
            }
        case "create-user":
            return {
                ...state,
                usersInfo: updateUsersInfo(state.usersInfo, action.payload.response),
                createUserRequestId: action.payload.requestId
            }
        case "delete-user":
            return {
                ...state,
                usersInfo: updateUsersInfo(state.usersInfo, action.payload.response, true),
                deleteUserRequestId: action.payload.requestId
            }
        case "console-permission-list":
            return {
                ...state,
                consolePermissionList: formatConsolePermissions(action.payload.response),
                consolePermissionListRequestId: action.payload.requestId
            }
        case "console-permission-set":
            return {
                ...state,
                consolePermissionList: updateConsolePermissionList(state.consolePermissionList, action.payload.response),
                consolePermissionSetRequestId: action.payload.requestId
            }
        case "console-permission-effective":
            return {
                ...state,
                consoleEffectivePermission: formatConsoleEffectivePermission(action.payload.response),
                consoleEffectivePermissionRequestId: action.payload.requestId
            }
        case "documents-info":
            return {
                ...state,
                documentsInfo: action.payload.response,
                documentsInfoRequestId: action.payload.requestId
            }
        case "document-content":
            return {
                ...state,
                documentContent: action.payload.response,
                documentContentRequestId: action.payload.requestId
            }
        case "document-accept": {
            return {
                ...state,
                documentAcceptResponse: action.payload.response,
                documentAcceptRequestId: action.payload.requestId
            }
        }
        case "recent-partner-merchants":
            return {
                ...state,
                recentMerchants: formatRecentMerchants(action.payload.response),
                recentMerchantsRequestId: action.payload.requestId
            }
        case "recent-loans":
            return {
                ...state,
                recentLoans: action.payload.response,
                recentLoansRequestId: action.payload.requestId
            }
        case "loan-review":
            return {
                ...state,
                loanReviewResult: action.payload.response,
                loanReviewRequestId: action.payload.requestId
            }
        case "submit-account-notification":
            return {
                ...state,
                accountNotificationResult: action.payload.response,
                accountNotificationRequestId: action.payload.requestId
            }
        case "plaid-webhook":
            return {
                ...state,
                plaidWebhookResult: action.payload.response,
                plaidWebhookRequestId: action.payload.requestId
            }
        case "concurrent-loans":
            return {
                ...state,
                concurrentLoansInfo: action.payload.response,
                concurrentLoansRequestId: action.payload.requestId
            }
        case "invoke-disbursement":
            return {
                ...state,
                disbursementInfo: action.payload.response,
                disbursementRequestId: action.payload.requestId
            }
        case "manual-loan-expiration":
            return {
                ...state,
                expirationInfo: action.payload.response,
                expirationRequestId: action.payload.requestId
            }
        case "invoke-origination":
            return {
                ...state,
                originationInfo: action.payload.response,
                originationRequestId: action.payload.requestId
            }
        case "get-alloy-data":
            return {
                ...state,
                alloyData: action.payload.response,
                alloyRequestId: action.payload.requestId
            }
        case "get-partner-access-token":
        case "get-merchant-access-token":
            return {
                ...state,
                authToken: action.payload.response,
                authTokenRequestId: action.payload.requestId
            }
        case "borrower-profile-list": {
            return {
                ...state,
                borrowerProfileList: formatBorrowerProfile(action.payload.response),
                borrowerProfileListRequestId: action.payload.requestId
            }
        }
        case "borrower-profile-cancel": {
            return {
                ...state,
                borrowerProfileList: formatBorrowerProfile(action.payload.response),
                borrowerProfileCancelRequestId: action.payload.requestId
            }
        }
        case "borrower-profile-restore": {
            return {
                ...state,
                borrowerProfileList: formatBorrowerProfile(action.payload.response),
                borrowerProfileRestoreRequestId: action.payload.requestId
            }
        }
        case "borrower-profile-remove": {
            return {
                ...state,
                borrowerProfileList: formatBorrowerProfile(action.payload.response),
                borrowerProfileRemoveRequestId: action.payload.requestId
            }
        }
        case "borrower-credit-file-list": {
            return {
                ...state,
                borrowerCreditReportList: formatBorrowerCreditFile(action.payload.response),
                borrowerCreditReportListRequestId: action.payload.requestId
            }
        }
        case "borrower-credit-file-cancel": {
            return {
                ...state,
                borrowerCreditReportList: formatBorrowerCreditFile(action.payload.response),
                borrowerCreditReportCancelRequestId: action.payload.requestId
            }
        }
        case "borrower-credit-file-restore": {
            return {
                ...state,
                borrowerCreditReportList: formatBorrowerCreditFile(action.payload.response),
                borrowerCreditReportRestoreRequestId: action.payload.requestId
            }
        }
        case "borrower-credit-file-remove": {
            return {
                ...state,
                borrowerCreditReportList: formatBorrowerCreditFile(action.payload.response),
                borrowerCreditReportRemoveRequestId: action.payload.requestId
            }
        }
        case "signup-repair":
            return {
                ...state,
                signupRepairInfo: action.payload.response,
                signupRepairRequestId: action.payload.requestId
            }
        case "clean-signup-repair":
            return {
                ...state,
                signupRepairInfo: null
            }
        case "table-metadata":
            return {
                ...state,
                tableMetadata: action.payload.response,
                tableMetadataRequestId: action.payload.requestId
            }
        case "audit-logs": {
            return {
                ...state,
                auditLogs: action.payload.response,
                auditLogsRequestId: action.payload.requestId
            }
        }
        case "audit-trail": {
            return {
                ...state,
                auditTrail: action.payload.response,
                auditTrailRequestId: action.payload.requestId
            }
        }
        case "audit-meta": {
            return {
                ...state,
                auditMeta: action.payload.response,
                auditMetaRequestId: action.payload.requestId
            }
        }
        case "query-dynamodb":
            return {
                ...state,
                dynamoDBQueryResult: action.payload.response,
                dynamoDBQueryRequestId: action.payload.requestId
            }
        case "query-dynamodb-record":
            return {
                ...state,
                dynamoDBQueryResult: updateDynamoDBRecords(state.dynamoDBQueryResult, action.payload.response),
                dynamoDBQueryRequestId: action.payload.requestId
            }
        case "data-lake-query":
            return {
                ...state,
                dataLakeResponse: {...state.dataLakeResponse, [action.payload.response.template]: action.payload.response},
                dataLakeRequestId: action.payload.requestId
            }
        case "cloudwatch-log-groups":
            return {
                ...state,
                logGroupsResponse: action.payload.response,
                logGroupsRequestId: action.payload.requestId
            }
        case "cloudwatch-insights-query":
            return {
                ...state,
                insightsQueryResponse: action.payload.response,
                insightsQueryRequestId: action.payload.requestId
            }
        case "cloudwatch-recent-queries":
            return {
                ...state,
                insightsQueriesResponse: action.payload.response,
                insightsQueriesRequestId: action.payload.requestId
            }
        case "splunk-search":
            return {
                ...state,
                splunkSearchResponse: action.payload.response,
                splunkSearchRequestId: action.payload.requestId
            }
        case "loans-list":
            return {
                ...state,
                loanListInfo: formatLoanList(action.payload.response),
                loanListRequestId: action.payload.requestId
            }
        case "peach-get-borrower":
            return {
                ...state,
                peachBorrower: action.payload.response,
                peachBorrowerRequestId: action.payload.requestId
            }
        case "peach-get-user":
            return {
                ...state,
                peachUser: action.payload.response,
                peachUserRequestId: action.payload.requestId
            }
        case "peach-get-loan":
            return {
                ...state,
                peachLoan: action.payload.response,
                peachLoanRequestId: action.payload.requestId
            }
        case "peach-get-autopay-plan":
            return {
                ...state,
                peachAutopayPlan: action.payload.response,
                peachAutopayPlanRequestId: action.payload.requestId
            }
        case "peach-get-contact":
            return {
                ...state,
                peachContact: action.payload.response,
                peachContactRequestId: action.payload.requestId
            }
        case "peach-get-payment-instrument":
            return {
                ...state,
                peachPaymentInstrument: action.payload.response,
                peachPaymentInstrumentRequestId: action.payload.requestId
            }
        case "peach-get-document-descriptor":
            return {
                ...state,
                peachDocumentDescriptor: action.payload.response,
                peachDocumentDescriptorRequestId: action.payload.requestId
            }
        case "peach-cleanup":
            return {
                ...state,
                dynamoDBQueryResult: null,
                decryptedValue: null,
                peachBorrower: null,
                peachLoan: null,
                peachAutopayPlan: null,
                peachUser: null,
                peachDocumentDescriptor: null,
                peachPaymentInstrument: null,
                peachContact: null
            }
        case "create-prequal-link":
            return {
                ...state,
                prequalLink: action.payload.response,
                prequalLinkRequestId: action.payload.requestId
            }
        case "create-prequal-link-api":
            return {
                ...state,
                prequalLink: action.payload.response.prequalLink,
                prequalLinkRequestId: action.payload.requestId
            }
        case "create-legacy-signup":
            return {
                ...state,
                legacySignup: action.payload.response,
                legacySignupRequestId: action.payload.requestId
            }
        case "refund-create": {
            return {
                ...state,
                refundsInfo: action.payload.response,
                refundCreateRequestId: action.payload.requestId
            }
        }
        case "generate-report": {
            return {
                ...state,
                generateReportInfo: action.payload.response,
                generateReportRequestId: action.payload.requestId
            }
        }
        case "merchant-api-playground": {
            return {
                ...state,
                merchantApiResponse: {
                    ...state.merchantApiResponse,
                    [action.payload.response.template]: action.payload.response
                },
                merchantApiRequestId: action.payload.requestId
            }
        }
        case "api-error":
            return {
                ...state,
                apiError: action.payload
            };
        case "submit-settings":
        case "get-settings":
            if (state.settings && state.settings.profile && state.settings.profile !== action.payload.response.profile) {
                return {
                    ...initState,
                    settings: convertSettings(action.payload.response),
                    settingsRequestId: action.payload.requestId
                };
            }
            return {
                ...state,
                settings: convertSettings(action.payload.response),
                settingsRequestId: action.payload.requestId
            };
        case "template-viewer-create-preview":
            const previewResponse = action.payload.response;
            const preview = previewResponse && previewResponse.hasOwnProperty('subject') && previewResponse.hasOwnProperty('body') ? previewResponse : initState.templateViewerHTML;
            return {
                ...state,
                templateViewerHTML: preview
            };
        case "sign-out":
            setCognitoCredentials(null);
            forgetUser();
            return initState;
        case "get-templates":
            const response = action.payload.response;
            const result = response && response.hasOwnProperty('templateIds') ? response : initState.templates;
            return {
                ...state,
                templates: result
            };
        case "get-one-template":
            const templateResponse = action.payload.response;
            const data = templateResponse && templateResponse.hasOwnProperty('id') && templateResponse.hasOwnProperty('templateString') && templateResponse.hasOwnProperty('uniqueTemplateVariables') ? templateResponse : initState.templateViewerHTML;
            return {
                ...state,
                templateData: data
            };
        case "approve-refund":
            const approveRefundResponse = action.payload.response;
            return {
                ...state,
                approveRefundResponse
            };
        case "reset-approve-refund":
            return {
                ...state,
                approveRefundResponse: null
            };
        case "update-payouts-status":
            return {
                ...state,
                payoutsStatusUpdate: action.payload.response
            }
        case "get-feature-config":
            const getResponse = action.payload.response;
            const getConfig = getResponse && getResponse.hasOwnProperty('entityId') && getResponse.hasOwnProperty('featureConfigData') ? getResponse : initState.featureConfig;
            getConfig.featureConfigData = JSON.parse(getResponse.featureConfigData);
            return {
                ...state,
                featureConfig: getConfig,
                featureConfigRequestId: action.payload.requestId
            };
        case "create-feature-config":
            const createResponse = action.payload.response;
            const createConfig = createResponse && createResponse.hasOwnProperty('entityId') && createResponse.hasOwnProperty('featureConfigData') ? createResponse : initState.featureConfig;
            createConfig.featureConfigData = JSON.parse(createResponse.featureConfigData);
            return {
                ...state,
                featureConfig: createConfig,
                featureConfigCreatedRequestId: action.payload.requestId
            };
        case "update-feature-config":
            const updateResponse = action.payload.response;
            const updateConfig = updateResponse && updateResponse.hasOwnProperty('entityId') && updateResponse.hasOwnProperty('featureConfigData') ? updateResponse : initState.featureConfig;
            updateConfig.featureConfigData = JSON.parse(updateResponse.featureConfigData);
            return {
                ...state,
                featureConfig: updateConfig,
                featureConfigUpdatedRequestId: action.payload.requestId
            };
        case "delete-feature-config":
            const deleteResponse = action.payload.response;
            const deletedConfig = deleteResponse && deleteResponse.hasOwnProperty('entityId') && deleteResponse.hasOwnProperty('isDeleted') ? deleteResponse : initState.featureConfig;
            return {
                ...state,
                featureConfig: deletedConfig,
                featureConfigDeletedRequestId: action.payload.requestId
            };
        default:
            return state;
    }
}

export default consoleReducer;
