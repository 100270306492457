import React from 'react';
import {useSelector} from "react-redux";

import Grid from "@material-ui/core/Grid";

import ExPanel from "./ExPanel";
import ErrorMessage from "./ErrorMessage";
import ReloadButton from "./ReloadButton";
import Progress from "./Progress";
import useApiRequest from "../hooks/useApiRequest";
import {isDisabled} from "../store/actions/aws";
import UpdatePartnerAllocationConfigButton from "./UpdatePartnerAllocationConfigButton";
import styles from './PartnerAllocationConfigsPanel.module.css';
import useCredentials from "../hooks/useCredentials";

const getConfig = (partner, name) => {
    if (name === 'test') {
        return partner.testPoolConfig;
    }

    return partner.poolConfig;
}

export default function PartnerAllocationConfigsPanel({submitPartnerAllocationConfigsRequest, submitRequest}) {
    const {username} = useCredentials();
    const [count, setCount] = React.useState(0);
    const partnersAllocationConfigInfoState = useSelector(state => state.console.partnersAllocationConfigInfo);
    const [partnersAllocationConfigInfo, setPartnersAllocationConfigInfo] = React.useState(partnersAllocationConfigInfoState);
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();
    const [prevRequestId, setPrevRequestId] = React.useState(requestId);

    if (prevRequestId !== requestId && !requestInProgress) {
        setPrevRequestId(requestId);
        setPartnersAllocationConfigInfo(partnersAllocationConfigInfoState);
    }

    const handleInfoRequest = () => {
        submitPartnerAllocationConfigsRequest({}, newRequest());
    }

    const handleUpdateRequest = () => {
        let partners = partnersAllocationConfigInfo.partners;
        submitRequest('update-partner-allocation-configs', {partners, username}, newRequest());
    }

    const totalRatio = () => {
        if (!partnersAllocationConfigInfo) {
            return 0;
        }
        return partnersAllocationConfigInfo.partners
            .map(partner => getConfig(partner).ratio + getConfig(partner, 'test').ratio)
            .reduce((v1, v2) => v1 + v2, 0);
    }

    function EditableRatio(params) {
        const [editMode, setEditMode] = React.useState(false);
        const [text, setText] = React.useState(getConfig(params.partner, params.mode).ratio);

        const handleClick = () => {
            setEditMode(true);
        };

        const isValid = (ratio) => {
            return !!ratio && ratio >= 0.0 && ratio <= 1.0;
        }

        const handleBlur = (e) => {
            setEditMode(false);
            let partner = getConfig(params.partner, params.mode);
            if (!isValid(e.target.value)) {
                setText(partner.ratio);
                return;
            }
            partner.ratio = parseFloat(e.target.value);
            if (params.allPartners.length === 2) {
                params.allPartners
                    .filter(config => config.pool !== params.partner.pool)
                    .forEach(p => {
                        let partner2 = getConfig(p, params.mode);
                        partner2.ratio = parseFloat((1.0 - partner.ratio).toFixed(10));
                    });
            }
            setPartnersAllocationConfigInfo(partnersAllocationConfigInfo);
            setCount(count + 1);
        };

        const handleKeyDown = (e) => {
            if (e.keyCode === 13) {
                handleBlur(e);
            }
        };

        const handleChange = (e) => {
            setText(e.target.value);
        };

        return (
            <div onDoubleClick={handleClick}>
                {editMode ? (
                    <input type="text" value={text} onKeyDown={handleKeyDown} onChange={handleChange} onBlur={handleBlur}/>
                ) : (
                    <div>{text}</div>
                )}
            </div>
        );
    }

    const PartnersGrid = (data) => {
        if (!partnersAllocationConfigInfo) {
            return <div>No data</div>;
        }
        let configName = data.configName;

        const renderDescription = (description) => {
            if (!description) {
                return 'not defined!';
            }
            return description
                .replaceAll(' and ', '<b> and </b>')
                .replaceAll(' or ', '<b> or </b>');
        }

        return <div key={count}>
            <table className={styles.allocationTable} style={{width: '100%'}}>
                <thead>
                <tr>
                    <th style={{width: '8%'}}>Pool</th>
                    <th style={{width: '8%'}}>Randomization Ratio</th>
                    <th style={{width: '8%'}}>Bank (Lender Eligibility)</th>
                    <th style={{width: '26%'}}>Lender Eligibility Criteria</th>
                    <th style={{width: '25%'}}>Allocation Eligibility Criteria</th>
                    <th style={{width: '25%'}}>Confirmation Eligibility Criteria</th>
                </tr>
                </thead>
                <tbody>
                {partnersAllocationConfigInfo.partners &&
                    partnersAllocationConfigInfo.partners
                        .map(partner => {
                            let bankConfigs = getConfig(partner, configName).bankConfigs;
                            bankConfigs = bankConfigs ? bankConfigs : []
                            return <tr key={configName + partner.pool}>
                                <td>{partner.pool}</td>
                                <td><EditableRatio partner={partner} allPartners={partnersAllocationConfigInfo.partners}
                                                 mode={configName}/>
                                </td>
                                <td>
                                    {bankConfigs.map((config, i) => {
                                        return <div key={configName + config.bank}>
                                            <input type="checkbox"
                                                   onClick={e => config['enabled'] = e.target.checked}
                                                   defaultChecked={config.enabled}/>
                                            {config.bank}
                                            {i + 1 !== bankConfigs.length && <hr/>}
                                        </div>
                                    })}
                                </td>
                                <td>
                                    {bankConfigs.map((config, i) => {
                                        return <div key={configName + config.bank}>
                                            <div dangerouslySetInnerHTML={{ __html:
                                                    renderDescription(config.lenderCriteriaDescription)}}></div>
                                            {i + 1 !== bankConfigs.length && <hr/>}
                                        </div>
                                    })}
                                </td>
                                <td>
                                    {bankConfigs.map((config, i) => {
                                        return <div key={configName + config.bank}>
                                            <div dangerouslySetInnerHTML={{ __html:
                                                    renderDescription(config.criteriaDescription)}}></div>
                                            {i + 1 !== bankConfigs.length && <hr/>}
                                        </div>
                                    })}
                                </td>
                                <td>
                                    {bankConfigs.map((config, i) => {
                                        return <div key={configName + config.bank}>
                                            <div dangerouslySetInnerHTML={{ __html:
                                                    renderDescription(config.confirmationCriteriaDescription)}}></div>
                                            {i + 1 !== bankConfigs.length && <hr/>}
                                        </div>
                                    })}
                                </td>
                            </tr>
                        })
                }
                </tbody>
            </table>
        </div>
    }

    return (
        <ExPanel title="Capital Partners" hidden={isDisabled('partner-allocation-configs')}>
            <Grid item xs={12}>
                <ReloadButton onClick={handleInfoRequest} disabled={requestInProgress}/>
                <UpdatePartnerAllocationConfigButton onClick={handleUpdateRequest}
                                                     disabled={requestInProgress || totalRatio() !== 2.0}/>
                <Progress show={requestInProgress} requestId={requestId}/>
                <ErrorMessage errorMessage={requestError}/>
                <h3>Configuration</h3>
                <PartnersGrid configName={'prod'}/>
            </Grid>
        </ExPanel>
    )
}
