import 'date-fns';
import React from 'react';
import {useSelector} from "react-redux";

import DateFnsUtils from '@date-io/date-fns';
import Grid from "@material-ui/core/Grid";
import {MuiPickersUtilsProvider} from '@material-ui/pickers';

import ExPanel from "./ExPanel";
import SubmitButton from "./SubmitButton";
import Alert from "./Alert";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import FormInput from "./FormInput";
import useApiRequest from "../hooks/useApiRequest";
import {isDisabled} from "../store/actions/aws";
import useFields from "../hooks/useFields";

const formFields = {
    "expirationDate": {
        label: 'Invoke expiration for date',
        initValue: null,
        type: 'date',
        order: 1
    },
    "appId": {
        label: 'Get loan expiration info by Loan / Payout ID',
        initValue: '',
        order: 2
    }
}

export default function InvokeExpirationPanel({submitRequest}) {
    const expirationInfo = useSelector(state => state.console.expirationInfo);
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();
    const [fields, handleOnChange, handleOnClean] = useFields(formFields);

    const handleOnChangeCustom = (e) => {
        handleOnClean();
        handleOnChange(e);
    }

    const handleInvokeExpiration = () => {
        submitRequest('manual-loan-expiration', fields, newRequest());
    }

    const ExpirationInfo = () => {
        if (expirationInfo && !requestInProgress) {
            return <Alert severity={'success'}>{expirationInfo}</Alert>
        }
        return null;
    }

    return (
        <ExPanel title="Loan Expiration Actions" hidden={isDisabled('manual-loan-expiration')}
                 tooltip="Invoke ManualLoanExpirationLambda">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid item xs={12}>
                    <form>
                        <FormInput
                            key="expirationDate"
                            name="expirationDate"
                            onChange={handleOnChangeCustom}
                            getEditValue={name => fields[name]}
                            formFields={formFields}
                        />
                        <div>OR</div>
                        <FormInput
                            disabled={requestInProgress}
                            key="appId"
                            name="appId"
                            onChange={handleOnChangeCustom}
                            getEditValue={name => fields[name]}
                            formFields={formFields}
                        />
                        <SubmitButton onClick={handleInvokeExpiration}/>
                    </form>
                    <ErrorMessage errorMessage={requestError}/>
                    <Progress show={requestInProgress} requestId={requestId}/>
                    <ExpirationInfo/>
                </Grid>
            </MuiPickersUtilsProvider>
        </ExPanel>
    )
}
