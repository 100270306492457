import React from "react";
import {useForm} from "react-hook-form";

import JSONInput from 'react-json-editor-ajrm';
import locale    from 'react-json-editor-ajrm/locale/en';

import Grid from "@material-ui/core/Grid";
import {Chip} from "@material-ui/core";

import ExPanel from "./ExPanel";
import {useSelector} from "react-redux";
import useApiRequest from "../hooks/useApiRequest";
import {FormInputCtr} from "./FormInputCtr";
import SubmitButton from "./SubmitButton";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import JsonView from "./JsonView";

const firstTemplateName = "create_merchant_min";

const templates = {
    [firstTemplateName]: {
        label: 'Create merchant (minimal)',
        method: 'POST',
        path: 'merchants',
        json: {
            "merchantData": {
                "business": {
                    "federalEIN": "123456789"
                },
                "externalId": "f76eebfc-c61d-468e-97ff-9ff8850c38fd"
            }
        }
    },
    "create_merchant_conditionally_approved": {
        label: 'Create merchant (conditionally approved)',
        method: 'POST',
        path: 'merchants',
        json: {
            "merchantData": {
                "externalId": "d4b0d0e8-c482-404d-9b8e-ffa719871985",
                "initiatorEmail": "test+initiator@wisetack.com",
                "business": {
                    "federalEIN": "123456789",
                    "businessLegalName": "Test Enigma Success",
                    "doingBusinessAs": "Doing Business As",
                    "businessAddress": "707 1/2 6th Ave",
                    "email": "test+business@wisetack.com",
                    "city": "Novato",
                    "zip": "12345",
                    "phoneNumber": "+15555551234",
                    "industry": "HS",
                    "reviews": {
                        "reviewCount": 10,
                        "averageRating": 4.8,
                        "firstReviewDate": "2022-12-01"
                    }
                },
                "executive": {
                    "firstName": "FirstName",
                    "lastName": "LastName",
                    "email": "test+representative@wisetack.com",
                    "mobileNumber": "+15555554321",
                    "ownership": "45%",
                    "homeAddress": "808 1/2 6th Ave",
                    "city": "Novato",
                    "zip": "12345",
                    "dob": "1978-05-18",
                    "ssn4": "1234"
                },
                "signupPurpose": "merchantBusinessAlloyStatus=KYC Approved"
            }
        }
    },
    "create_merchant_fully_approved": {
        label: 'Create merchant (fully approved)',
        method: 'POST',
        path: 'merchants',
        json: {
            "merchantData": {
                "externalId": "fbaa9f8b-233d-4227-bc63-bcd2ebcfa584",
                "initiatorEmail": "test+initiator@wisetack.com",
                "business": {
                    "federalEIN": "123456789",
                    "businessLegalName": "Test Enigma Success",
                    "doingBusinessAs": "Doing Business As",
                    "businessAddress": "707 1/2 6th Ave",
                    "email": "test+business@wisetack.com",
                    "city": "Novato",
                    "zip": "12345",
                    "phoneNumber": "+15555551234",
                    "industry": "HS",
                    "reviews": {
                        "reviewCount": 10,
                        "averageRating": 4.8,
                        "firstReviewDate": "2022-12-01"
                    }
                },
                "executive": {
                    "firstName": "FirstName",
                    "lastName": "LastName",
                    "email": "test+representative@wisetack.com",
                    "mobileNumber": "+15555554321",
                    "ownership": "45%",
                    "homeAddress": "808 1/2 6th Ave",
                    "city": "Novato",
                    "zip": "12345",
                    "dob": "1978-05-18",
                    "ssn4": "1234"
                },
                "signupPurpose": "merchantBusinessAlloyStatus=KYC Approved",
                "paymentAccount": {
                    "accountName": "Account for testing",
                    "subtype": "CHECKING",
                    "accountNumber": "12345678911234567",
                    "routingNumber": "002102984"
                },
                "documentsAccepted": true
            }
        }
    },
    "create_merchant_high_dispute_rate": {
        label: 'Create merchant (high dispute rate)',
        method: 'POST',
        path: 'merchants',
        json: {
            "merchantData": {
                "externalId": "cb61cc23-b5af-4337-b1a7-e8b93e868d1a",
                "initiatorEmail": "test+initiator@wisetack.com",
                "business": {
                    "federalEIN": "123456789",
                    "businessLegalName": "Test Enigma Success",
                    "doingBusinessAs": "Doing Business As",
                    "businessAddress": "707 1/2 6th Ave",
                    "email": "test+business@wisetack.com",
                    "city": "Novato",
                    "zip": "12345",
                    "phoneNumber": "+15555551234",
                    "industry": "HS",
                    "ttmSales": 10000.0,
                    "ttmDisputes": 8000.0,
                    "reviews": {
                        "reviewCount": 10,
                        "averageRating": 4.8,
                        "firstReviewDate": "2022-12-01"
                    }
                },
                "executive": {
                    "firstName": "FirstName",
                    "lastName": "LastName",
                    "email": "test+representative@wisetack.com",
                    "mobileNumber": "+15555554321",
                    "ownership": "45%",
                    "homeAddress": "808 1/2 6th Ave",
                    "city": "Novato",
                    "zip": "12345",
                    "dob": "1978-05-18",
                    "ssn4": "1234"
                }
            }
        }
    },
    "create_merchant_insufficient_rating": {
        label: 'Create merchant (insufficient rating)',
        method: 'POST',
        path: 'merchants',
        json: {
            "merchantData": {
                "externalId": "2de2f957-eee4-41f2-ad73-c25b591c81a1",
                "initiatorEmail": "test+initiator@wisetack.com",
                "business": {
                    "federalEIN": "123456789",
                    "businessLegalName": "Test Enigma Success",
                    "doingBusinessAs": "Doing Business As",
                    "businessAddress": "707 1/2 6th Ave",
                    "email": "test+business@wisetack.com",
                    "city": "Novato",
                    "zip": "12345",
                    "phoneNumber": "+15555551234",
                    "industry": "HS",
                    "reviews": {
                        "reviewCount": 20,
                        "averageRating": 3.5,
                        "firstReviewDate": "2022-12-01"
                    }
                },
                "executive": {
                    "firstName": "FirstName",
                    "lastName": "LastName",
                    "email": "test+representative@wisetack.com",
                    "mobileNumber": "+15555554321",
                    "ownership": "45%",
                    "homeAddress": "808 1/2 6th Ave",
                    "city": "Novato",
                    "zip": "12345",
                    "dob": "1978-05-18",
                    "ssn4": "1234"
                }
            }
        }
    },
    "create_merchant_actions_required": {
        label: 'Create merchant (actions required)',
        method: 'POST',
        path: 'merchants',
        json: {
            "merchantData": {
                "externalId": "c03ce4d1-abc3-4805-a9c0-1bf89abe11f5",
                "initiatorEmail": "test+initiator@wisetack.com",
                "business": {
                    "federalEIN": "123456789",
                    "businessLegalName": "Test Enigma Success",
                    "doingBusinessAs": "Doing Business As",
                    "businessAddress": "707 1/2 6th Ave",
                    "email": "test+business@wisetack.com",
                    "city": "Novato",
                    "zip": "12345",
                    "phoneNumber": "+15555551234",
                    "industry": "HS",
                    "reviews": {
                        "reviewCount": 10,
                        "averageRating": 4.8,
                        "firstReviewDate": "2022-12-01"
                    }
                },
                "executive": {
                    "firstName": "FirstName",
                    "lastName": "LastName",
                    "email": "test+representative@wisetack.com",
                    "mobileNumber": "+15555554321",
                    "ownership": "45%",
                    "homeAddress": "808 1/2 6th Ave",
                    "city": "Novato",
                    "zip": "12345",
                    "dob": "1978-05-18",
                    "ssn4": "1234"
                },
                "signupPurpose": "merchantFeinMatch=false"
            }
        }
    },
    "update_merchant_activation": {
        label: 'Update merchant (activation)',
        method: 'PATCH',
        path: 'merchants/{merchantId}',
        json: {
            "data": [
                {
                    "op": "add",
                    "path": "/documentsAccepted",
                    "value": true
                },
                {
                    "op": "add",
                    "path": "/paymentAccount",
                    "value": {
                        "accountName": "Account for testing",
                        "subtype": "CHECKING",
                        "accountNumber": "12345678911234567",
                        "routingNumber": "002102984"
                    }
                }
            ]
        }
    },
    "update_merchant_business": {
        label: 'Update merchant (business data)',
        method: 'PATCH',
        path: 'merchants/{merchantId}',
        json: {
            "data": [
                {
                    "op": "add",
                    "path": "/business",
                    "value": {
                        "federalEIN": "123456789",
                        "businessLegalName": "Test Enigma Success",
                        "doingBusinessAs": "Doing Business As",
                        "businessAddress": "707 1/2 6th Ave",
                        "email": "test+business@wisetack.com",
                        "city": "Novato",
                        "zip": "12345",
                        "phoneNumber": "+15555551234",
                        "industry": "HS",
                        "ttmSales": 20000,
                        "ttmDisputes": 20,
                        "reviews": {
                            "reviewCount": 10,
                            "averageRating": 4.8,
                            "firstReviewDate": "2022-12-01"
                        }
                    }
                }
            ]
        }
    },
    "update_merchant_executive": {
        label: 'Update merchant (executive data)',
        method: 'PATCH',
        path: 'merchants/{merchantId}',
        json: {
            "data": [
                {
                    "op": "add",
                    "path": "/executive",
                    "value": {
                        "firstName": "FirstName",
                        "lastName": "LastName",
                        "email": "test+representative@wisetack.com",
                        "mobileNumber": "+15555554321",
                        "ownership": "45%",
                        "homeAddress": "808 1/2 6th Ave",
                        "city": "Novato",
                        "zip": "12345",
                        "dob": "1978-05-18",
                        "ssn4": "1234"
                    }
                }
            ]
        }
    },
    "update_merchant_initiator_email": {
        label: 'Update merchant (initiator email)',
        method: 'PATCH',
        path: 'merchants/{merchantId}',
        json: {
            "data": [
                {
                    "op": "add",
                    "path": "/initiatorEmail",
                    "value": "test+initiator@wisetack.com"
                }
            ]
        }
    },
    "update_merchant_add_owner": {
        label: 'Update merchant (add owner)',
        method: 'PATCH',
        path: 'merchants/{merchantId}',
        json: {
            "data": [
                {
                    "op": "add",
                    "path": "/owners/-",
                    "value": {
                        "firstName": "FirstName",
                        "lastName": "LastName",
                        "email": "test+representative@wisetack.com",
                        "mobileNumber": "+15555554321",
                        "ownership": "45%",
                        "homeAddress": "808 1/2 6th Ave",
                        "city": "Novato",
                        "zip": "12345",
                        "dob": "1978-05-18",
                        "ssn4": "1234"
                    }
                }
            ]
        }
    },
    "update_merchant_with_test": {
        label: 'Update merchant (test op example)',
        method: 'PATCH',
        path: 'merchants/{merchantId}',
        json: {
            "data": [
                {
                    "op": "test",
                    "path": "/business/doingBusinessAs",
                    "value": "Doing Business As"
                },
                {
                    "op": "replace",
                    "path": "/business/doingBusinessAs",
                    "value": "Doing Business As Updated"
                }
            ]
        }
    },
    "update_merchant_remove_owners": {
        label: 'Update merchant (remove owners)',
        method: 'PATCH',
        path: 'merchants/{merchantId}',
        json: {
            "data": [
                {
                    "op": "remove",
                    "path": "/owners",
                    "value": null
                }
            ]
        }
    },
    "get_job_result": {
        label: 'Get job result',
        method: 'GET',
        path: 'jobs/{jobId}',
        json: {}
    },
    "get_merchant": {
        label: 'Get merchant',
        method: 'GET',
        path: 'merchants/{merchantId}',
        json: {}
    },
    "get_merchants": {
        label: 'Get merchants',
        method: 'GET',
        path: 'merchants',
        json: {}
    }
}

const formFields = {
    "accountId": {
        label: 'Account',
        type: 'partner-select',
        initValue: ''
    },
    "merchantId": {
        label: 'Merchant ID',
        initValue: ''
    },
    "jobId": {
        label: 'Job ID',
        initValue: ''
    }
}
const fieldList = ["accountId", "merchantId", "jobId"];

const requestType = 'merchant-api-playground';

export default function MerchantApiPlaygroundPanel({submitRequest, submitPartnersInfoRequest}) {
    const formsData = useSelector(state => state.console.formsData[requestType]);
    const merchantApiResponse = useSelector(state => state.console.merchantApiResponse);

    const [template, setTemplate] = React.useState(firstTemplateName);
    const [jsObject, setJsObject] = React.useState(templates[firstTemplateName].json);

    formFields.accountId.dataRequest = submitPartnersInfoRequest;
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();

    const { handleSubmit, control } = useForm({
        defaultValues: {
            ...formsData
        }
    });

    const onSubmit = (data) => {
        const requestData = {
            ...data,
            template,
            body: jsObject,
            method: templates[template].method,
            path: templates[template].path
        }
        submitRequest(requestType, requestData, newRequest())
    }

    const handleOnChange = (data) => {
        if (data.jsObject) {
            setJsObject(data.jsObject)
            templates[template].json = data.jsObject;
        }
    }

    const handleOnTemplateClick = (name) => {
        setTemplate(name);
        setJsObject(templates[name].json)
    }

    const JsonTemplates = () => {
        return <>
            {Object.keys(templates).map((name) => (
                <Chip
                    key={name}
                    label={templates[name].label}
                    size="small"
                    color={name === template ? "primary" : "default"}
                    onClick={() => handleOnTemplateClick(name)}
                    style={{
                        marginLeft: '5px',
                        marginBottom: '5px'
                    }}
                />
            ))}
        </>
    }

    const MerchantApiResponse = () => {
        if (!merchantApiResponse || !merchantApiResponse[template] || requestInProgress) {
            return null;
        }
        const response = merchantApiResponse[template];
        return <ExPanel title={`${templates[template].label} response`} expanded={true}>
            <Grid item xs={12}>
                <Chip
                    label={`Status: ${response.status}`}
                    size="small"
                    color={response.status < 400 ? "primary" : "secondary"}
                    style={{
                        marginRight: '15px'
                    }}
                />
                <JsonView
                    data={response.body}
                    shouldExpandNode={(keyPath, data, level) => level < 2}
                />
                <ErrorMessage errorMessage={response.errorMessage}/>
            </Grid>
        </ExPanel>
    }

    return (
        <ExPanel title="Merchant API Playground">
            <Grid item xs={12}>
                <form>
                    {fieldList.map((name) => (
                        <FormInputCtr
                            key={name}
                            name={name}
                            formFields={formFields}
                            control={control}
                            disabled={requestInProgress}
                        />
                    ))}
                    <JsonTemplates/>
                    <ExPanel title={`${templates[template].method} /api/${templates[template].path}`} expanded={true}>
                        <JSONInput
                            id="a_unique_id"
                            theme="light_mitsuketa_tribute"
                            style={{ body: { fontSize: '14px', }}}
                            placeholder={templates[template].json}
                            onChange={handleOnChange}
                            locale={locale}
                            height="550px"
                            width="100%"
                        />
                    </ExPanel>
                    <SubmitButton onClick={handleSubmit(onSubmit)}/>
                    <ErrorMessage errorMessage={requestError}/>
                    <Progress show={requestInProgress} requestId={requestId}/>
                </form>
                <MerchantApiResponse/>
            </Grid>
        </ExPanel>
    )
}