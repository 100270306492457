import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {connect, useDispatch, useSelector} from 'react-redux';
import clsx from 'clsx';

import {createTheme, makeStyles, MuiThemeProvider} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Badge from '@material-ui/core/Badge';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ApartmentIcon from '@material-ui/icons/Apartment';
import BusinessIcon from '@material-ui/icons/Business';
import FolderSharedIcon from '@material-ui/icons/FolderShared';
import SettingsIcon from '@material-ui/icons/Settings';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import SecurityIcon from '@material-ui/icons/Security';
import DescriptionIcon from '@material-ui/icons/Description';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import StorageIcon from '@material-ui/icons/Storage';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ListAltIcon from '@material-ui/icons/ListAlt';
import MessageIcon from '@material-ui/icons/Message';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import {Chip, Tooltip} from "@material-ui/core";

import logo from '../assets/img/wisetack-logo.svg';
import {mainTheme} from "../utils/constants";
import {submitIpcRequest} from "../store/actions/consoleActions";
import LoginDialog from "./LoginDialog";
import useCredentials from "../hooks/useCredentials";
import {isElectron} from "../utils/electron";
import useApiRequest from "../hooks/useApiRequest";
import Progress from "./Progress";
import ErrorMessage from "./ErrorMessage";
import {isDisabled} from "../store/actions/aws";

function Copyright({submitIpcRequest}) {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link
                color="inherit"
                href="https://wisetack.com/"
                target="_blank"
                onClick={(e) => {e.preventDefault(); submitIpcRequest('open-external', {url: 'https://wisetack.com/'})}}
            >
                Wisetack
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    logo: {
        height: '22px',
        paddingRight: '20px',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
}));

const menuItems = [
    {
        path: '/',
        text: 'Dashboard',
        perm: [
            'recent-partner-merchants',
            'recent-loans'
        ],
        icon: <DashboardIcon/>
    },
    {
        path: '/partners',
        text: 'Partners',
        perm: [
            'create-partner',
            'industry-list',
            'partners-info',
            'get-partner-access-token'
        ],
        icon: <ApartmentIcon/>
    },
    {
        path: '/merchants',
        text: 'Merchants',
        perm: [
            'create-signup',
            'signup-info',
            'signup-list-info',
            'signup-repair',
            'merchants-inf',
            'transactions-info',
            'review-merchant-lambda',
            'get-alloy-data',
            'open-merchant-portal'
        ],
        icon: <BusinessIcon/>
    },
    {
        path: '/loans',
        text: 'Loan Applications',
        perm: [
            'manual-loan-expiration',
            'payment-link',
            'loan-info',
            'loans-list',
            'create-prequal-link-api',
            'create-prequal-link',
            'prequals-list',
            'refunds-list',
            'borrower-profile-list',
            'borrower-credit-file-list',
            'loan-review',
            'payouts-list',
            'refund-create',
            'concurrent-loans',
            'fico-floors-list'
        ],
        icon: <FolderSharedIcon/>
    },
    {
        path: '/documents',
        text: 'Documents',
        perm: [
            'report-files-list',
            'documents-info',
            'query-dynamodb'   // Peach raw data
        ],
        icon: <DescriptionIcon/>
    },
    {
        path: '/users',
        text: 'Users',
        perm: [
            'create-user',
            'users-info',
            'users-lockout-info',
            'console-permission-list'
        ],
        icon: <AccountBoxIcon/>
    },
    {
        path: '/payments',
        text: 'Payments (ACH)',
        perm: [
            'invoke-disbursement',
            'invoke-origination',
            'payments-list',
            'boarding-files-list',
            'partner-allocation-configs'
        ],
        icon: <AccountBalanceWalletIcon/>
    },
    {
        path: '/vault',
        text: 'Vault',
        perm: [
            'vault-encrypt',
            'vault-encrypt'
        ],
        icon: <SecurityIcon/>
    },
    {
        path: '/dynamodb',
        text: 'DynamoDB',
        perm: ['query-dynamodb'],
        icon: <ListAltIcon/>
    },
    {
        path: '/data_lake',
        text: 'Data Lake',
        perm: [
            'table-metadata',
            'data-lake-query'
        ],
        icon: <StorageIcon/>
    },
    {
        path: '/cloud_watch',
        text: 'Logs',
        perm: [
            'audit-logs',
            'cloudwatch-insights-query'
        ],
        icon: <ArrowForwardIosIcon/>
    },
    {
        path: '/templates',
        text: 'Message Templates',
        perm: [
        ],
        icon: <MessageIcon/>
    },
    {divider: 1},
    {
        path: '/settings',
        text: 'Settings',
        icon: <SettingsIcon/>
    }
]

const desktop = isElectron()

function AppLayout(props) {

    let history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();

    const open = useSelector(state => state.console.drawerOpened);
    const apiRequests = useSelector(state => state.console.apiRequests);
    const cognitoSignInRequestId = useSelector(state => state.console.cognitoSignInRequestId)

    const {profile, username, loginRequired, tokenExpired, savedUser} = useCredentials();
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();

    const [openLoginDialog, setOpenLoginDialog] = React.useState(loginRequired && !savedUser);
    const title = props.title;

    const sessionRefreshed = !!cognitoSignInRequestId && cognitoSignInRequestId === requestId

    useEffect(() => {
        if(loginRequired) {
            if (savedUser) {
                props.submitIpcRequest('refresh-cognito-session', savedUser, newRequest())
            } else if (history.location.pathname !== '/') {
                history.push("/")
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(sessionRefreshed) {
            props.submitIpcRequest(null, {
                requestTypeList: [
                    'metadata',
                    'audit-meta',
                    'partners-info',
                    'recent-partner-merchants',
                    'recent-loans',
                    'partner-allocation-configs'
                ]
            }, newRequest())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionRefreshed]);

    useEffect(() => {
        if(tokenExpired && !openLoginDialog) {
            setOpenLoginDialog(true)
        }
    }, [tokenExpired, openLoginDialog]);

    const handleDrawerOpen = (payload) => {
        dispatch({type: "drawer-opened", payload})
    };

    const handleMenuItemClick = (item) => {
        if (history.location.pathname !== item.path) {
            history.push(item.path);
        }
    }

    const MenuItems = () => {
        return (
            <div>
                <Divider/>
                <List>
                    {
                        menuItems.map(item => {
                            if (item.divider) {
                                return <Divider key={item.divider}/>
                            }
                            if (item.perm && item.perm.length > 0) {
                                let counter = 0
                                for (const element of item.perm) {
                                    if (isDisabled(element)) {
                                        counter++
                                    } else {
                                        break;
                                    }
                                }
                                if (counter === item.perm.length) {
                                    // all panels disabled
                                    return null
                                }
                            }
                            const selectedItemColor = history.location.pathname === item.path ? '#e4f1f1' : 'inherit';
                            return (
                                <ListItem key={item.path} style={{background: selectedItemColor}} button onClick={() => handleMenuItemClick(item)}>
                                    <ListItemIcon>
                                        {item.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={item.text}/>
                                </ListItem>
                            )
                        })
                    }
                </List>
            </div>
        )
    }

    const NotificationsButton = () => {
        let requestsCounter = 0;
        if (apiRequests) {
            requestsCounter = Object.keys(apiRequests).length;
        }
        return <IconButton color="inherit">
            <Badge badgeContent={requestsCounter} color="secondary">
                <NotificationsIcon/>
            </Badge>
        </IconButton>
    }

    const Profile = () => {
        if (!profile) {
            return null
        }
        const color = profile === 'wisetack' ? 'secondary' : 'default'
        const tooltip = profile === 'wisetack' ? 'Be careful, you are working with production data!' : ''

        return <Tooltip title={tooltip}><Chip
            onClick={() => !desktop && dispatch({type: "security-token-expired", payload: true})}
            label={profile}
            size="small"
            color={color}
        /></Tooltip>
    }

    const Username = () => {
        if (!username) {
            return null
        }
        return <Chip style={{marginLeft: "5px"}}
            label={username}
            size="small" onClick={() => !desktop && setOpenLoginDialog(true)}
        />
    }

    const handleLoginDialogClose = () => {
        setOpenLoginDialog(false);
    }

    const theme = createTheme(mainTheme);

    return (
        <MuiThemeProvider theme={theme}>
        <div className={classes.root}>
            <CssBaseline/>
            <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => handleDrawerOpen(true)}
                        className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                        {title}
                    </Typography>
                    <Profile/>
                    <Username/>
                    <NotificationsButton />
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                }}
                open={open}
            >
                <div className={classes.toolbarIcon}>
                    <Link
                        color="inherit"
                        href="https://wisetack.com/"
                        target="_blank"
                        onClick={(e) => {e.preventDefault(); props.submitIpcRequest('open-external', {url: 'https://wisetack.com/'})}}
                    >
                        <img className={classes.logo} src={logo} alt="wisetack-logo"/>
                    </Link>
                    <IconButton onClick={() => handleDrawerOpen(false)}>
                        <ChevronLeftIcon/>
                    </IconButton>
                </div>
                <MenuItems/>
            </Drawer>
            <main className={classes.content}>
                <div className={classes.appBarSpacer}/>
                <Container maxWidth="lg" className={classes.container}>
                    <ErrorMessage errorMessage={requestError}/>
                    <Progress show={!!requestId && requestInProgress} requestId={requestId}/>
                    {props.children}
                    <Box pt={4}>
                        <Copyright submitIpcRequest={props.submitIpcRequest}/>
                    </Box>
                </Container>
            </main>
            {openLoginDialog && <LoginDialog
                submitRequest={props.submitIpcRequest}
                onClose={handleLoginDialogClose}
            />}
        </div>
        </MuiThemeProvider>
    );
}

export default connect(null, {
    submitIpcRequest
})(
    AppLayout
);
