import React, {useEffect, useState} from 'react';
import {connect, useSelector} from "react-redux";
import useApiRequest from "../hooks/useApiRequest";
import AppLayout from "../components/AppLayout";
import { submitIpcRequest } from "../store/actions/consoleActions";
import TemplateSearch from "../components/TemplateSearch";
import TemplatesList from "../components/TemplatesList";
import ErrorMessage from "../components/ErrorMessage";
import Progress from "../components/Progress";

function TemplatesPage(props) {
    // const
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();
    const templates = useSelector(state => state.console.templates);
    const [filter, setFilter] = useState('');

    const getFilteredTemplates = () => {
        return filter.length > 2 ? templates.templateIds.filter((template) => template.toLowerCase().includes(filter.toLowerCase())) : templates.templateIds;
    };

    useEffect(() => {
        props.submitIpcRequest('get-templates',{}, newRequest());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AppLayout title="Templates Viewer">
            <TemplateSearch setFilter={setFilter}/>
            <TemplatesList templates={getFilteredTemplates()} inProgress={requestInProgress} />
            <ErrorMessage errorMessage={requestError}/>
            <Progress show={requestInProgress} requestId={requestId}/>
            <br />
        </AppLayout>
    )
}

export default connect(null, {submitIpcRequest}) (
    TemplatesPage
);