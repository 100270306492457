import React from 'react';
import Panel from "./Panel";
import Box from "@material-ui/core/Box";
import SearchIcon from '@material-ui/icons/Search';
import TextField from "@material-ui/core/TextField";

function TemplateSearch({setFilter, inProgress, requestId}) {

    const handleOnChange = (e) => {
        setFilter(e.target.value);
    }

    return (
        <>
            <Panel title="Search templates">
            <br />
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <SearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                    <TextField  id="search"
                                label="Template Name"
                                variant="standard"
                                fullWidth sx={{ m: 1 }} onChange={(e) => handleOnChange(e)}/>
                </Box>
            </Panel>
            <br />
        </>
    )
}

export default TemplateSearch;