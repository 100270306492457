import React from 'react';
import SimpleSelect from "./SimpleSelect";
import TextField from "@material-ui/core/TextField";
const AUTH_AND_REVERSE = "AUTH_AND_REVERSE";
const AUTH_AND_SAVE = "AUTH_AND_SAVE";
const INHERIT_FROM_PARENT = "INHERIT_FROM_PARENT";

export default function FmpOptions({disabled, onFmpOptionsChange, value}) {

    const [fmpOptions, setFmpOptions] = React.useState({});
    const [error, setError] = React.useState(false);

    React.useEffect(() => {
        setFmpOptions(value);
    }, [value, disabled]);

    return <>
        <SimpleSelect
            value={fmpOptions.strategy || INHERIT_FROM_PARENT}
            name="strategy"
            onChange={e => {
                const updatedFmpOptions = {...fmpOptions, strategy: e.target.value};
                updatedFmpOptions.authAmount = updatedFmpOptions.strategy === AUTH_AND_REVERSE ? "1.00" : "0.00";
                setError(false);
                setFmpOptions(updatedFmpOptions);
                onFmpOptionsChange(updatedFmpOptions);
            }}
            disabled={disabled}
            label={"Auth Strategy"}
            data={[
                {id: '', name: ''},
                {id: AUTH_AND_REVERSE, name: AUTH_AND_REVERSE.replaceAll("_", " ")},
                {id: AUTH_AND_SAVE, name: AUTH_AND_SAVE.replaceAll("_", " ")},
                {id: INHERIT_FROM_PARENT, name: INHERIT_FROM_PARENT.replaceAll("_", " ")}
            ]}
        />
        {fmpOptions.strategy === AUTH_AND_REVERSE &&
            <TextField
                value={fmpOptions.authAmount}
                name={"authAmount"}
                label={"Max Auth Amount"}
                type={"text"}
                error={error && !disabled}
                helperText={error && !disabled ? "Max Auth Amount must be equals or greater than 1 and have two decimal places" : ""}
                disabled={disabled}
                onChange={e => {
                    const updatedFmpOptions = {...fmpOptions, authAmount: e.target.value};
                    setError(!/^\d+\.\d{2}$/.test(e.target.value) || parseFloat(e.target.value) < 1);
                    setFmpOptions(updatedFmpOptions);
                    onFmpOptionsChange(updatedFmpOptions);
                }}
                onFocus={e => {
                   e.target.select();
                }}
                onKeyPress={e => {
                    const key = e.key;
                    const inputValue = e.target.value;
                    if (key === '.' || key === ',') {
                        if (inputValue.includes('.')) {
                            e.preventDefault();
                        }
                    } else if (/\d/.test(key)) {
                        return;
                    } else {
                        e.preventDefault();
                    }
                }}
                variant="outlined"
                margin="normal"
                size="small"
                fullWidth
            />
        }
    </>
}
