import React from 'react';

import {makeStyles} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
    styles: {
        marginBottom: '10px',
        marginLeft: '10px'
    },
}));

export default function UpdatePartnerAllocationConfigButton({hide, onClick, disabled}) {
    const classes = useStyles();

    if (hide) {
        return null;
    }
    return <Button
        className={classes.styles}
        disabled={disabled}
        variant="contained"
        size="small"
        color="primary"
        onClick={onClick}
    >
        Update
    </Button>
}