import React from "react";
import {useSelector} from "react-redux";

import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import Grid from "@material-ui/core/Grid";
import {DataGrid} from "@mui/x-data-grid";
import Chip from "@material-ui/core/Chip";
import {Button, DialogActions} from "@material-ui/core";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';

import ExPanel from "./ExPanel";
import FormInput from "./FormInput";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import JsonSwitch from "./JsonSwitch";
import JsonView from "./JsonView";
import {initMonths, initYears} from "../utils/constants";
import LoanInfoDialog from "./LoanInfoDialog";
import useApiRequest from "../hooks/useApiRequest";
import useFields from "../hooks/useFields";
import {isDisabled} from "../store/actions/aws";

const formFields = {
    "merchantId": {
        label: 'Merchant ID',
        initValue: ''
    },
    "statuses": {
        label: 'Statuses',
        type: 'select',
        data: [
            {id: 'Started'},
            {id: 'Approved'},
            {id: 'Offer Locked'},
            {id: 'Confirmed'},
            {id: 'Paid'},
            {id: 'Declined'},
            {id: 'Expired'},
            {id: 'Canceled'}
        ],
        multiple: true,
        initValue: []
    },
    "years": {
        label: 'Years',
        type: 'select',
        data: initYears(),
        multiple: true,
        initValue: []
    },
    "months": {
        label: 'Months',
        type: 'select',
        data: initMonths,
        multiple: true,
        initValue: []
    },
    "limit": {
        label: 'Page Size',
        type: 'number',
        initValue: 8
    }
};

const fieldList = [
    "merchantId",
    "statuses",
    "years",
    "months",
    "limit"
];

const TransactionsInfo = ({columns, loading, jsonView, transactionsInfo, pageSize, onPageChange, rowCount, page}) => {
    if (!transactionsInfo || !transactionsInfo.transactions) {
        return null;
    }
    if (jsonView) {
        return <JsonView data={transactionsInfo} shouldExpandNode={(keyPath, data, level) => level < 3}/>
    }
    const rows = loading ? [{id: 'loading...'}] : transactionsInfo.transactions;
    return <div style={{height: 400, width: '100%'}}>
        <DataGrid
            rows={rows}
            pagination={true}
            page={page}
            paginationMode="server"
            rowCount={rowCount}
            onPageChange={onPageChange}
            hideFooterSelectedRowCount={true}
            pageSize={pageSize}
            rowsPerPageOptions={[pageSize]}
            columns={columns}
            loading={loading}
            density='compact'
        />
    </div>
}

const requestType = 'transactions-info';

export default function MerchantTransactionsPanel({submitRequest, openPaymentLink}) {
    const transactionsInfo = useSelector(state => state.console.transactionsInfo);
    const formsData = useSelector(state => state.console.formsData[requestType]);
    const dataPresent = !!transactionsInfo && !!transactionsInfo.transactions;

    let rowCount = 0;
    let currentPage = 0;
    if (dataPresent) {
        const cursor = transactionsInfo.cursor;
        if (cursor) {
            rowCount = cursor.startIndex + cursor.size;
            if (cursor.afterCursorId) {
                rowCount = rowCount + 1;
            }
            if (cursor.page) {
                currentPage = cursor.page;
            }
        } else {
            rowCount = transactionsInfo.transactions.length;
        }
    }

    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();
    const [fields, handleOnChange] = useFields(formFields, formsData);
    const [selectedRow, setSelectedRow] = React.useState('');
    const [jsonView, setJsonView] = React.useState(false);
    const [page, setPage] = React.useState(currentPage);
    const [openDialog, setOpenDialog] = React.useState(false);

    const handleInfoRequest = ({cursorId, page}) => {
        if (!page) {
            page = 0;
            setPage(0);
        }
        const data = {
            ...fields,
            cursorId,
            page
        }
        submitRequest(requestType, data, newRequest());
    }

    const handleTransactionOpen = (row) => {
        setSelectedRow(row);
        setOpenDialog(true);
    }

    const handleDialogClose = () => {
        setOpenDialog(false);
    }

    const handleOpenPaymentLink = (row) => {
        setSelectedRow(row);
        openPaymentLink({shortId: row.id})
    }

    const idCellRenderer = ({row, value}) => {
        if (requestInProgress) {
            return "Loading...";
        }
        const isSelected = !!selectedRow && selectedRow.id === row.id
        const deleteIcon = isSelected ? <CheckCircleIcon/> : null;
        const onDelete = isSelected ? () => setSelectedRow(null) : null;
        return <CopyToClipboard text={row.id}><Chip
            label={value}
            size="small"
            color={row.blocked ? "secondary" : "default"}
            onClick={() => {
                handleTransactionOpen(row)
            }}
            deleteIcon={deleteIcon}
            onDelete={onDelete}
        /></CopyToClipboard>
    }

    const consumerRenderer = ({row}) => {
        const value = row.consumer;
        if (!value || !value.shortName) {
            return null;
        }
        return value.shortName;
    }
    const phoneRenderer = ({row}) => {
        if (requestInProgress) {
            return null;
        }
        const value = row.consumer;
        const phone = !!value ? value.phone : null;
        return <>
            {phone}
            <CopyToClipboard text={row.id}><PlayCircleOutlineIcon
                style={{marginLeft: "5px", cursor: "pointer"}}
                color="action"
                onClick={() => handleOpenPaymentLink(row)}
            /></CopyToClipboard>
        </>
    }

    const statusRenderer = ({value}) => {
        if (!value) {
            return null;
        }
        let color = "primary";
        if (value === 'Declined' || value === 'Rejected' || value === 'Canceled') {
            color = "secondary"
        } else if (value === 'Expired') {
            color = "default"
        }
        return <Chip label={value} size="small" color={color}/>
    }

    const handlePageChange = (params) => {
        if (transactionsInfo.cursor) {
            const cursor = transactionsInfo.cursor;
            if (params.page > page && cursor.afterCursorId) {
                handleInfoRequest({cursorId: cursor.afterCursorId, page: params.page});
                setPage(params.page);
            }
            if (params.page < page && cursor.beforeCursorId) {
                handleInfoRequest({cursorId: cursor.beforeCursorId, page: params.page});
                setPage(params.page);
            }
        }
    }

    const columns = [
        {field: 'id', headerName: 'Transaction ID', width: 150, renderCell: idCellRenderer},
        {field: 'createdAt', headerName: 'Created At', width: 150},
        {field: 'consumer.shortName', headerName: 'Consumer', width: 150, renderCell: consumerRenderer},
        {field: 'consumer.phone', headerName: 'Phone', width: 150, renderCell: phoneRenderer},
        {field: 'status', headerName: 'Status', width: 150, renderCell: statusRenderer},
        {field: 'requestedLoanAmount', headerName: 'Requested', width: 130},
        {field: 'approvedLoanAmount', headerName: 'Approved', width: 120},
        {field: 'settledLoanAmount', headerName: 'Settled', width: 120},
    ]

    return (
        <ExPanel title="Merchant Transactions" hidden={isDisabled(requestType)}>
            <Grid item xs={12}>
                <form>
                    {fieldList.map((name) => (
                        <FormInput
                            key={name}
                            name={name}
                            onChange={handleOnChange}
                            getEditValue={name => fields[name]}
                            formFields={formFields}
                        />
                    ))}
                    <DialogActions>
                        <Button disabled={requestInProgress} variant="contained" color="primary" onClick={handleInfoRequest}>Submit</Button>
                    </DialogActions>
                </form>
                <ErrorMessage errorMessage={requestError}/>
                <Progress show={requestInProgress} requestId={requestId}/>
                <JsonSwitch hide={!dataPresent} jsonView={jsonView} setJsonView={setJsonView}/>
                <TransactionsInfo
                    rowCount={rowCount}
                    page={currentPage}
                    pageSize={fields.limit}
                    onPageChange={handlePageChange}
                    columns={columns}
                    loading={requestInProgress}
                    transactionsInfo={transactionsInfo}
                    jsonView={jsonView}
                />
                {openDialog && <LoanInfoDialog
                    submitRequest={submitRequest}
                    openPaymentLink={openPaymentLink}
                    onClose={handleDialogClose}
                    loanId={selectedRow.id}
                />}
            </Grid>
        </ExPanel>
    )
}
