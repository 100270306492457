import 'date-fns';
import React, {useState} from 'react';

import Grid from "@material-ui/core/Grid";

import ExPanel from "./ExPanel";
import {Typography} from "@material-ui/core";
import SubmitButton from "./SubmitButton";
import useApiRequest from "../hooks/useApiRequest";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

export default function SettlementFiles({submitRequest}) {
    const [selectedOption, setSelectedOption] = useState("citizens");
    const [, , , newRequest] = useApiRequest();

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleButtonClick = (event) => {
        event.preventDefault();
        const requestData = {
            action: 'DOWNLOAD',
            partnerBank: `${selectedOption.toUpperCase()}`
        };
        submitRequest('download-settlement-files', requestData, newRequest());
    };


    return (
        <ExPanel title="SettlementFiles">
                <Grid container spacing={2}>
                    <Grid item>
                        <Typography variant="subtitle1">
                            <b>Select partner:</b>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Select value={selectedOption} onChange={handleOptionChange}>
                            <MenuItem value="citizens">Citizens</MenuItem>
                            <MenuItem value="hatch">Hatch</MenuItem>
                        </Select>
                    </Grid>
                    <SubmitButton onClick={handleButtonClick} />
                </Grid>
        </ExPanel>
    )
}
