import React, {useCallback} from "react";
import {MaskedTextField} from "./MaskedTextField";
import {phoneInputInterceptor} from "../utils/interceptors";

const phoneMask = ['+', '1', '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]

export const PhoneTextField = ({...props}) => {

    const inputInterceptor = useCallback(phoneInputInterceptor, [])

    return <MaskedTextField
        {...props}
        inputInterceptor={inputInterceptor}
        mask={phoneMask}
    />
}
