import React from 'react';
import {connect} from "react-redux";

import AppLayout from "../components/AppLayout";
import PaymentLinkPanel from "../components/PaymentLinkPanel";
import LoanInfoPanel from "../components/LoanInfoPanel";
import LoanReviewPanel from "../components/LoanReviewPanel";
import PrequalsPanel from "../components/PrequalsPanel";
import RefundsPanel from "../components/RefundsPanel";
import CreateRefundPanel from "../components/CreateRefundPanel";
import BorrowerProfilePanel from "../components/BorrowerProfilePanel";
import PrequalLinkPanel from "../components/PrequalLinkPanel";
import BorrowerCreditFilePanel from "../components/BorrowerCreditFilePanel";
import PayoutsPanel from "../components/PayoutsPanel";
import LoanListPanel from "../components/LoanListPanel";
import ConcurrentLoansPanel from "../components/ConcurrentLoansPanel";
import FicoFloors from "../components/FicoFloors";
import UpdatePayoutsStatusPanel from "../components/UpdatePayoutsStatusPanel";
import InvokeExpirationPanel from "../components/InvokeExpirationPanel";

import {
    submitIpcRequest,
    openPaymentLink,
    openPrequalLink
} from "../store/actions/consoleActions";

function LoansPage(props) {
    return (
        <AppLayout title="Loan Applications">
            <PaymentLinkPanel submitRequest={props.submitIpcRequest} openPaymentLink={props.openPaymentLink}/>
            <LoanInfoPanel submitRequest={props.submitIpcRequest} openPaymentLink={props.openPaymentLink}/>
            <LoanListPanel submitRequest={props.submitIpcRequest}/>
            <PrequalLinkPanel submitRequest={props.submitIpcRequest}/>
            <PrequalsPanel submitRequest={props.submitIpcRequest} openPrequalLink={props.openPrequalLink}/>
            <PayoutsPanel submitRequest={props.submitIpcRequest}/>
            <RefundsPanel submitRequest={props.submitIpcRequest}/>
            <CreateRefundPanel submitRequest={props.submitIpcRequest}/>
            <BorrowerProfilePanel submitRequest={props.submitIpcRequest}/>
            <BorrowerCreditFilePanel submitRequest={props.submitIpcRequest}/>
            <LoanReviewPanel submitRequest={props.submitIpcRequest}/>
            <ConcurrentLoansPanel submitRequest={props.submitIpcRequest}/>
            <FicoFloors submitRequest={props.submitIpcRequest}></FicoFloors>
            <UpdatePayoutsStatusPanel submitRequest={props.submitIpcRequest}></UpdatePayoutsStatusPanel>
            <InvokeExpirationPanel submitRequest={props.submitIpcRequest}/>
        </AppLayout>
    )
}

export default connect(null, {
    submitIpcRequest,
    openPaymentLink,
    openPrequalLink
})(
    LoansPage
);
