import React from 'react';
import {useSelector} from "react-redux";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import ExPanel from "./ExPanel";
import SubmitButton from "./SubmitButton";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import useApiRequest from "../hooks/useApiRequest";
import {isDisabled} from "../store/actions/aws";

export default function LoanReviewPanel({submitRequest}) {
    const loanReviewResult = useSelector(state => state.console.loanReviewResult);
    const [loanApplicationId, setLoanApplicationId] = React.useState('');
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();
    const [outcome, setOutcome] = React.useState('Approved');
    const [userName, setUserName] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [category, setCategory] = React.useState('FRAUD_REVIEW');

    const handleLoanReviewRequest = () => {
        if (loanApplicationId) {
            submitRequest('loan-review', {loanApplicationId, outcome, userName, password, category}, newRequest());
        }
    }

    const LoanReviewResult = () => {
        if (loanReviewResult && !requestInProgress) {
            return <div>{loanReviewResult}</div>
        }
        return null;
    }

    let options = null;

    if (category === "FRAUD_REVIEW") {
        options = [<MenuItem value={"Approved"}>Approved</MenuItem>,<MenuItem value={"Denied"}>Denied</MenuItem>]
    } else if (category === "EFA") {
        options = [<MenuItem value={"POSITIVE"}>Positive</MenuItem>,<MenuItem value={"NEGATIVE"}>Negative</MenuItem>]
    }

    return (
        <ExPanel title="Loan Application Review" tooltip="Uses same endpoint as manual review from CRM" hidden={isDisabled('loan-review')}>
            <Grid item xs={12}>
                <form style={{display:"flex", flexDirection: "column"}}>
                    <TextField
                        label="User Name"
                        variant="outlined"
                        margin="normal"
                        size="small"
                        required
                        fullWidth
                        value={userName}
                        onChange={e => {setUserName(e.target.value);}}
                    />
                    <TextField
                        label="Password"
                        variant="outlined"
                        margin="normal"
                        size="small"
                        required
                        fullWidth
                        value={password}
                        onChange={e => {setPassword(e.target.value);}}
                    />
                    <TextField
                        label="Loan Application ID"
                        variant="outlined"
                        margin="normal"
                        size="small"
                        required
                        fullWidth
                        value={loanApplicationId}
                        onChange={e => {setLoanApplicationId(e.target.value);}}
                    />
                    <FormControl variant="outlined" style={{marginTop: "15px"}}>
                        <InputLabel id="select-category-label">Category</InputLabel>
                        <Select
                            labelId="select-category-label"
                            id="select-category"
                            value={category}
                            margin="dense"
                            onChange={e => setCategory(e.target.value)}
                            label="Category"
                        >
                            <MenuItem value={"FRAUD_REVIEW"}>Persona</MenuItem>
                            <MenuItem value={"EFA"}>EFA</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl variant="outlined" style={{marginTop: "20px"}}>
                        <InputLabel id="select-outcome-label">Outcome</InputLabel>
                        <Select
                            labelId="select-outcome-label"
                            id="select-outcome"
                            value={outcome}
                            margin="dense"
                            onChange={e => setOutcome(e.target.value)}
                            label="Outcome"
                        >
                            {options}
                        </Select>
                    </FormControl>
                    <SubmitButton onClick={handleLoanReviewRequest}/>
                    <ErrorMessage errorMessage={requestError}/>
                    <Progress show={requestInProgress} requestId={requestId}/>
                    <LoanReviewResult />
                </form>
            </Grid>
        </ExPanel>
    )
}