import React from 'react';
import {connect} from "react-redux";

import AppLayout from "../components/AppLayout";
import AuthTokenPanel from "../components/AuthTokenPanel";
import MerchantsInfoPanel from "../components/MerchantsInfoPanel";
import CreateSignupPanel from "../components/CreateSignupPanel";
import {
    getAuthToken,
    submitIpcRequest,
    openSignup,
    openPaymentLink
} from "../store/actions/consoleActions";
import MerchantPortalPanel from "../components/MerchantPortalPanel";
import MerchantTransactionsPanel from "../components/MerchantTransactionsPanel";
import AlloyDataPanel from "../components/AlloyDataPanel";
import SignupInfoPanel from "../components/SignupInfoPanel";
import PrequalLinkPanel from "../components/PrequalLinkPanel";
import SignupRepairPanel from "../components/SignupRepairPanel";
import MerchantReviewPanel from "../components/MerchantReviewPanel";
import SignupListPanel from "../components/SignupListPanel";
import MerchantApiPlaygroundPanel from "../components/MerchantApiPlaygroundPanel";

function MerchantsPage({getAuthToken, submitIpcRequest, openSignup, openPaymentLink}) {

    const handlePartnersInfoRequest = React.useCallback((data, requestId) => {
        submitIpcRequest('partners-info', data, requestId)
    }, [submitIpcRequest]);

    const handleSignupInfoRequest = React.useCallback((data, requestId) => {
        submitIpcRequest('signup-info', data, requestId)
    }, [submitIpcRequest]);

    return (
        <AppLayout title="Merchants">
            <CreateSignupPanel
                submitPartnersInfoRequest={handlePartnersInfoRequest}
                openSignup={openSignup}
                submitSignup={(data, requestId) => submitIpcRequest('create-signup', data, requestId)}
            />
            <SignupInfoPanel
                submitSignupInfoRequest={handleSignupInfoRequest}
                openSignup={openSignup}
            />
            <SignupListPanel
                openSignup={openSignup}
                submitRequest={submitIpcRequest}
            />
            <SignupRepairPanel
                submitRequest={submitIpcRequest}
            />
            <MerchantsInfoPanel
                submitPartnersInfoRequest={handlePartnersInfoRequest}
                submitSignupInfoRequest={handleSignupInfoRequest}
                openSignup={openSignup}
                submitRequest={submitIpcRequest}
                openPaymentLink={openPaymentLink}
            />
            <MerchantTransactionsPanel
                submitRequest={submitIpcRequest}
                openPaymentLink={openPaymentLink}
            />
            <MerchantReviewPanel
                submitRequest={submitIpcRequest}
            />
            <AlloyDataPanel
                submitRequest={submitIpcRequest}
            />
            <PrequalLinkPanel
                submitRequest={submitIpcRequest}
            />
            <AuthTokenPanel getAuthToken={getAuthToken}/>
            <MerchantApiPlaygroundPanel
                submitRequest={submitIpcRequest}
            />
            <MerchantPortalPanel submitRequest={submitIpcRequest}/>
        </AppLayout>
    )
}

export default connect(null, {
    getAuthToken,
    submitIpcRequest,
    openSignup,
    openPaymentLink
})(
    MerchantsPage
);

