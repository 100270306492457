import React from "react";
import {useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import DateFnsUtils from "@date-io/date-fns";

import Grid from "@material-ui/core/Grid";
import {Button, DialogActions} from "@material-ui/core";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";

import ExPanel from "./ExPanel";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import {FormInputCtr} from "./FormInputCtr";
import useApiRequest from "../hooks/useApiRequest";
import {getFieldList} from "../utils/format";
import JsonView from "./JsonView";
import {isDisabled} from "../store/actions/aws";

const formFields = {
    "external": {
        label: 'External',
        type: "switch",
        initValue: false,
        hide: () => false,
        order: 1
    },
    "loanApplicationId": {
        label: 'Loan Application ID',
        rules: {
            required: 'Loan Application ID required'
        },
        initValue: '',
        hide: () => false,
        order: 2
    },
    "payoutId": {
        label: 'Payout ID',
        initValue: '',
        hide: (external) => external,
        order: 3
    },
    "newLoanAmount": {
        label: 'New Loan Amount',
        initValue: '',
        hide: (external) => external,
        order: 4
    },
    "refundAmount": {
        label: 'Refund Amount',
        initValue: '',
        hide: (external) => !external,
        rules: {
            required: 'Refund Amount required'
        },
        order: 5
    },
    "refundDate": {
        label: 'Refund Date',
        type: 'date',
        initValue: null,
        hide: (external) => external,
        order: 6
    },
    "refundReason": {
        label: 'Refund Reason',
        initValue: '',
        hide: (external) => external,
        order: 7
    },
    "fullReversal": {
        label: 'Full Reversal',
        type: 'select',
        data: [
            {
                id: 'TRUE'
            },
            {
                id: 'FALSE'
            },
        ],
        initValue: null,
        rules: {
            required: 'Parameter required'
        },
        hide: (external) => external,
        order: 8
    },
    "refundNotes": {
        label: 'Refund Notes',
        initValue: '',
        hide: (external) => external,
        order: 9
    }
}

const RefundsInfo = ({refundsInfo, hide}) => {
    if (hide || !refundsInfo) {
        return null;
    }
    return <JsonView data={refundsInfo.loanRefunds || refundsInfo} shouldExpandNode={(keyPath, data, level) => level < 2}/>;
}

const fieldList = getFieldList(formFields)

const requestType = 'refund-create';

export default function CreateRefundPanel({submitRequest}) {
    const formsData = useSelector(state => state.console.formsData[requestType]);
    const refundsInfo = useSelector(state => state.console.refundsInfo);
    const refundCreateRequestId = useSelector(state => state.console.refundCreateRequestId);

    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();
    const refundCreated = !!refundCreateRequestId && refundCreateRequestId === requestId;

    const { handleSubmit, control, resetField, watch } = useForm({
        defaultValues: {
            ...formsData
        }
    });

    const watchExternal = watch("external");

    const onSubmit = (fields) => {
        submitRequest(requestType, fields, newRequest());
    }

    const handleOnClean = () => {
        fieldList.forEach(name => resetField(name, {defaultValue: formFields[name].initValue}));
    }

    return (
        <ExPanel title="Create Refund" hidden={isDisabled(requestType)}>
            <Grid item xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <form>
                        {fieldList.map((name) => (
                            <FormInputCtr
                                key={name}
                                name={name}
                                formFields={formFields}
                                control={control}
                                disabled={requestInProgress}
                                hide={formFields[name].hide(watchExternal)}
                            />
                        ))}
                    </form>
                </MuiPickersUtilsProvider>
                <DialogActions>
                    <Button disabled={requestInProgress} variant="contained" color="primary" onClick={handleOnClean}>Clean</Button>
                    <Button disabled={requestInProgress} variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>Submit</Button>
                </DialogActions>
                <ErrorMessage errorMessage={requestError}/>
                <Progress show={requestInProgress} requestId={requestId}/>
                <RefundsInfo hide={requestInProgress || !refundCreated} refundsInfo={refundsInfo}/>
            </Grid>
        </ExPanel>)
}
