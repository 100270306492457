import React from "react";

import {
    Button,
    Dialog, DialogActions, DialogContent, DialogTitle,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from "@material-ui/core";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import UndoIcon from "@material-ui/icons/Undo";
import {useForm} from "react-hook-form";
import useSettings from "../hooks/useSettings";
import DraggableDialog from "./DraggableDialog";
import {FormInputCtr} from "./FormInputCtr";
import {getFieldList} from "../utils/format";
import EditIcon from "@material-ui/icons/Edit";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

const formFields = {
    "action": {
        label: 'Action',
        dataType: 'supportedPermissions',
        type: 'select',
        initValue: '',
        rules: {
            required: 'Action required'
        },
        order: 1
    },
    "state": {
        label: 'State',
        data: [{id: 'ENABLE'},{id: 'DISABLE'}],
        type: 'select',
        initValue: '',
        rules: {
            required: 'State required'
        },
        order: 2
    }
}
const formFieldList = getFieldList(formFields)

const EditItemDialog = ({onClose, onSave, data}) => {

    if(!data) {
        data = {
            action: "",
            state: ""
        }
    }

    const { handleSubmit, control } = useForm({
        defaultValues: {
            ...data
        }
    });
    useSettings(formFields)

    return <Dialog
        PaperComponent={DraggableDialog}
        aria-labelledby="draggable-dialog-title"
        open={true}
        fullWidth={true}
    >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
           Select Permissions
        </DialogTitle>
        <DialogContent dividers={true}>
            <form>
                {formFieldList.map((name) => (
                    <FormInputCtr
                        key={name}
                        name={name}
                        formFields={formFields}
                        control={control}
                    />
                ))}
            </form>
        </DialogContent>
        <DialogActions>
            <Button variant="contained" color="primary" onClick={handleSubmit(onSave)}>Save</Button>
            <Button variant="contained" onClick={onClose}>Close</Button>
        </DialogActions>
    </Dialog>
}

const PermissionItem = ({item, onRemove, onSave}) => {
    const [openDialog, setOpenDialog] = React.useState(false);

    const handleSave = (data) => {
        setOpenDialog(false)
        onSave(data)
    }

    return <>
        <TableRow>
            <TableCell>
                {item.action}
            </TableCell>
            <TableCell>
                {item.state}
            </TableCell>
            <TableCell>
                <Tooltip title={"Edit"}>
                    <EditIcon color={"action"} cursor={"pointer"} onClick={() => setOpenDialog(true)}/>
                </Tooltip>
                <Tooltip title={"Remove"}>
                    <HighlightOffIcon color={"action"} cursor={"pointer"} onClick={onRemove}/>
                </Tooltip>
            </TableCell>
        </TableRow>
        {openDialog && <EditItemDialog onClose={() => setOpenDialog(false)} onSave={handleSave} data={item}/>}
    </>
}

export default function PermissionItemList({items, onRemove, onAdd, onUpdate, onUndo}) {
    const [openDialog, setOpenDialog] = React.useState(false);

    const handleAddItem = (item) => {
        onAdd(item)
        setOpenDialog(false)
    }

    return <>
        <Typography variant="h6" gutterBottom component="div">
            PermissionItems
        </Typography>
        <TableContainer component={Paper}>
            <Table size="small" aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell>Action</TableCell>
                        <TableCell>State</TableCell>
                        <TableCell>
                            <Tooltip title={"Add"}>
                                <ControlPointIcon color={"primary"} cursor={"pointer"} onClick={() => setOpenDialog(true)}/>
                            </Tooltip>
                            <Tooltip title={"Undo"}>
                                <UndoIcon color={"primary"} cursor={"pointer"} onClick={onUndo}/>
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    { items &&
                        items.map((item) => (<PermissionItem key={item.action}
                                                       item={item}
                                                       onRemove={() => onRemove(item)}
                                                       onSave={onUpdate}
                        />))}
                </TableBody>
            </Table>
        </TableContainer>
        {openDialog &&
        <EditItemDialog onClose={() => setOpenDialog(false)} onSave={handleAddItem}/>
        }
    </>
}
