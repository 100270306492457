import React from 'react';
import Panel from "./Panel";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TextField from "@material-ui/core/TextField";

export default function TemplateFieldsEditor({fieldMap, onValueChange}) {
    return (
        <Panel title="Template Fields" height="max-content">
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Value</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {fieldMap.map((field) => (
                    <TableRow key={field}>
                        <TableCell>{'${' + field + '}'}</TableCell>
                        <TableCell><TextField id={field} label={field} fullWidth sx={{ m: 0.5 }} variant="standard" onChange={(e) => {onValueChange(field, e.target.value)}}/></TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
        </Panel>
    )
}