import React from 'react';
import PropTypes from "prop-types";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Paper,
    Typography
} from "@material-ui/core";
import ErrorMessage from "./ErrorMessage";

const DeleteLoansConfirmationModal = props => {

    return (
        <Dialog open={props.open} scroll={"paper"} aria-describedby={"delete-loans-confirmation-modal"} fullWidth={false} maxWidth={"lg"}>
            <div>
                <DialogTitle>Bulk loan deletion confirmation</DialogTitle>
                <DialogContent dividers>
                    <Paper variant={"outlined"}>
                        {
                            props.loading && !props.errorMessage &&
                            <div style={{ display: 'flex', flexFlow: 'column', alignItems: "center" }}>
                                <CircularProgress color={"primary"}/>
                                <Typography>{props.loadingMessage}</Typography>
                            </div>
                        }
                        {
                            !props.loading && !props.errorMessage &&
                            <div style={{padding: "20px"}}>
                                <div>
                                    <h3>You will be deleting all loans and corresponding payouts shown in this
                                        list.</h3>
                                    <DialogActions>
                                        <Button variant={"contained"} color={"secondary"}
                                                onClick={props.handleCancel}>Cancel</Button>
                                        <Button variant={"contained"} color={"primary"}
                                                onClick={props.handleConfirm}>Confirm</Button>
                                    </DialogActions>
                                </div>
                            </div>
                        }
                        {
                            props.errorMessage && <ErrorMessage errorMessage={props.errorMessage}/>
                        }
                    </Paper>
                </DialogContent>
            </div>
        </Dialog>
    );
};

DeleteLoansConfirmationModal.propTypes = {
    open: PropTypes.bool.isRequired,
    handleCancel: PropTypes.func.isRequired,
    handleConfirm: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    loadingMessage: PropTypes.string,
    errorMessage: PropTypes.string
};

export default DeleteLoansConfirmationModal;