import React, {useMemo} from 'react';
import PropTypes from "prop-types";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Paper,
    Typography
} from "@material-ui/core";
import {replaceLineBreaksWithHTML} from "../utils/format";
import ErrorMessage from "./ErrorMessage";

const RenderHTMLModal = props => {

    const hasSubject = useMemo(() => (props.subject !== null && props.subject !== '' && !props.loading), [props.subject, props.loading]);

    return (
        <Dialog open={props.open} scroll={"paper"} aria-describedby={"template-viewer-preview-modal"} fullWidth={true} maxWidth={"lg"}>
            <div>
                <DialogTitle>
                    {props.dialogTitle}
                </DialogTitle>
                <DialogContent dividers>
                    {hasSubject && <h2 style={{ display: 'flex', flexFlow: 'column', alignItems: "center" }}>{props.subject}</h2>}
                    <Paper variant={"outlined"}>
                        {
                            props.loading && !props.errorMessage &&
                            <div style={{ display: 'flex', flexFlow: 'column', alignItems: "center" }}>
                                <CircularProgress color={"primary"}/>
                                <Typography>{props.loadingMessage}</Typography>
                            </div>
                        }
                        {
                            !props.loading && !props.errorMessage &&
                            <div style={{"padding": "20px"}} dangerouslySetInnerHTML={{ __html: replaceLineBreaksWithHTML(props.html) }} />
                        }
                        {
                            props.errorMessage && <ErrorMessage errorMessage={props.errorMessage}/>
                        }
                    </Paper>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose}  variant={"contained"} color={"primary"}>Close</Button>
                </DialogActions>
            </div>
        </Dialog>
    );
};

RenderHTMLModal.defaultProps = {
    loadingMessage: "Loading, please wait..."
}

RenderHTMLModal.propTypes = {
    html: PropTypes.string,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    dialogTitle: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    loadingMessage: PropTypes.string,
    errorMessage: PropTypes.string
};

export default RenderHTMLModal;