import React from 'react';
import {connect} from "react-redux";

import AppLayout from "../components/AppLayout";
import InvokeDisbursementPanel from "../components/InvokeDisbursementPanel";
import PaymentsPanel from "../components/PaymentsPanel";
import InvokeOriginationPanel from "../components/InvokeOriginationPanel";
import {submitIpcRequest} from "../store/actions/consoleActions";
import BoardingFilesPanel from "../components/BoardingFilesPanel";
import GenerateReportPanel from "../components/GenerateReportPanel";
import PartnerAllocationConfigsPanel from "../components/PartnerAllocationConfigsPanel";
import CitizensBalanceIDPanel from "../components/CitizensBalanceIDPanel";
import SettlementFiles from "../components/SettlementFiles";
import UploadHatchNachaFilesPanel from "../components/UploadHatchNachaFiles";

function PaymentsPage(props) {
    return (
        <AppLayout title="Payments">
            <InvokeDisbursementPanel submitRequest={props.submitIpcRequest}/>
            <PaymentsPanel submitRequest={props.submitIpcRequest}/>
            <InvokeOriginationPanel submitRequest={props.submitIpcRequest}/>
            <BoardingFilesPanel submitRequest={props.submitIpcRequest}/>
            <GenerateReportPanel submitRequest={props.submitIpcRequest}/>
            <PartnerAllocationConfigsPanel
                submitPartnerAllocationConfigsRequest={(data, requestId) =>
                    props.submitIpcRequest('partner-allocation-configs', data, requestId)}
                submitRequest={props.submitIpcRequest}
            />
            <CitizensBalanceIDPanel submitRequest={props.submitIpcRequest}/>
            <UploadHatchNachaFilesPanel submitRequest={props.submitIpcRequest}/>
            <SettlementFiles submitRequest={props.submitIpcRequest}/>
        </AppLayout>
    )
}

export default connect(null, {submitIpcRequest})(
    PaymentsPage
);
