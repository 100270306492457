import React, {useEffect, useState} from 'react';
import useApiRequest from "../hooks/useApiRequest";
import {submitIpcRequest} from "../store/actions/consoleActions";
import {useParams} from "react-router-dom";
import {connect, useSelector} from "react-redux";
import AppLayout from "../components/AppLayout";
import ErrorMessage from "../components/ErrorMessage";
import TemplateFieldsEditor from "../components/TemplateFieldsEditor";
import RenderHTMLModal from "../components/RenderHTMLModal";
import {Button} from "@material-ui/core";
import Panel from "../components/Panel";
import Editor from "react-simple-code-editor";
import {highlight, languages} from "prismjs/components/prism-core";
import Progress from "../components/Progress";

const TemplatePreviewPage = ({submitIpcRequest}) => {

    // const
    const [open, setOpen] = useState(false);
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();
    const [loadingOneTemplateRequestId, setloadingOneTemplateRequestId] = useState("");
    const [isLoadingOneTemplate, setIsLoadingOneTemplate] = useState(false);

    const apiRequests = useSelector(state => state.console.apiRequests);
    const html = useSelector(state => state.console.templateViewerHTML.body);
    const subject = useSelector(state => state.console.templateViewerHTML.subject);
    const templateVariables = useSelector(state => state.console.templateData.uniqueTemplateVariables);
    const templateString = useSelector(state => state.console.templateData.templateString);

    const [title, setTitle] = useState("");
    const [variableMap, setVariableMap] = useState({});

    const {templateId} = useParams();

    const onVariableMapValueChange = (key, value) => {
        setVariableMap((prevState) => {
            return {...prevState, [key]: value}
        })
    }

    useEffect(() => {
        if (apiRequests[loadingOneTemplateRequestId] === null) {
            setIsLoadingOneTemplate(false);
        }
    }, [apiRequests, loadingOneTemplateRequestId]);

    useEffect(() => {
        if (!!templateId) {
            setTitle(templateId);
            const requestId = newRequest();
            setloadingOneTemplateRequestId(requestId);
            submitIpcRequest('get-one-template', templateId, requestId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templateId]);

    const handleRequest = () => {
        submitIpcRequest('template-viewer-create-preview', {
            "templateId": templateId,
            "templateVariables": variableMap
        }, newRequest());
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleOpen = () => {
        handleRequest();
        setOpen(true);
    }

    const renderBody = () => {
        if (isLoadingOneTemplate) {
            return <Progress show={requestInProgress} requestId={requestId}/>;
        } else {
            return (
                <>
                    <TemplateFieldsEditor fieldMap={templateVariables} onValueChange={onVariableMapValueChange}/>
                    <br/>
                    <Panel title="Template message" height={650}>
                        <Editor
                            value={templateString}
                            highlight={(code) => highlight(code, languages.json, 'json')}
                            style={{
                                fontFamily: '"Fira code", "Fira Mono", monospace',
                                fontSize: 14,
                                userSelect: 'none',
                                overflow: 'auto'
                            }}
                        />
                    </Panel>
                    <RenderHTMLModal html={html} handleClose={handleClose} open={open} dialogTitle={title}
                                     loading={requestInProgress} loadingMessage={"Rendering message preview..."}
                                     errorMessage={requestError} subject={subject}/>
                    <br/>
                    <Button size={"large"} variant={"contained"} color={"primary"} onClick={handleOpen}>Preview</Button>
                </>
            )
        }
    }

    return (
        <AppLayout title={"Previewing - '" + title + "'"}>
            {renderBody()}
            <ErrorMessage errorMessage={requestError}/>
        </AppLayout>
    )
}

export default connect( null, { submitIpcRequest})
(
    TemplatePreviewPage
);
