import React from 'react';
import {useSelector} from "react-redux";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from "@material-ui/core";

import FormInput from "./FormInput";
import DraggableDialog from "./DraggableDialog";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import Alert from "./Alert";
import {partnerFormFieldList, partnerFormFields} from "../utils/constants";
import useFields from "../hooks/useFields";
import useApiRequest from "../hooks/useApiRequest";
import useApiRequestExecuted from "../hooks/useApiRequestExecuted";
import useSettings from "../hooks/useSettings";
import ProductPlanList from "./ProductPlanList";
import useProductPlans from "../hooks/useProductPlans";
import FmpOptions from "./FmpOptions";

const formFields = partnerFormFields;
const fieldList = partnerFormFieldList;
const updateRequestType = 'update-partner';
const deleteRequestType = 'delete-partner';
const getFeatureConfigRequestType = 'get-feature-config';
const createFeatureConfigRequestType = 'create-feature-config';
const updateFeatureConfigRequestType = 'update-feature-config';
const deleteFeatureConfigRequestType = 'delete-feature-config';

export default function EditPartnerDialog({data, onClose, submitRequest}) {
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();
    const partnerDeleted = useApiRequestExecuted('deletePartnerRequestId', requestId);
    const partnerUpdated = useApiRequestExecuted('updatePartnerRequestId', requestId);
    const userData = useSelector(state => state.console.cognitoSignIn);
    const fmpConfig = useSelector(state => state.console.featureConfig);
    const [fmpConfigEdited, setFmpConfigEdited] = React.useState({});
    const [fields, handleOnChange] = useFields(formFields, data);
    const {supportedPlans, handlePlanAdd, handlePlanUpdate, handlePlanRemove, handlePlanUndo} = useProductPlans(data.supportedPlans)
    const [warning, setWarning] = React.useState(null);
    const [showNotification, setShowNotification] = React.useState(false);

    useSettings(formFields)

    React.useEffect(() => {
        getFmpConfig();
        // eslint-disable-next-line
    }, []);

    const handleOnSave = () => {
        setWarning('');
        setShowNotification(false);

        if (fmpConfigEdited.strategy === 'AUTH_AND_REVERSE' && (!/^\d+\.\d{2}$/.test(fmpConfigEdited.authAmount) || parseFloat(fmpConfigEdited.authAmount) < 1)) {
            setWarning(<><div><b>Problems found</b></div><div>Max Auth Amount must be greater than 1 and have two decimal places</div></>);
            return;
        }

        if (fmpConfigEdited) {
            handleSaveFmpOptions();
        }
        submitRequest(updateRequestType, {...fields, supportedPlans}, newRequest());
        setShowNotification(true);
    }

    const handleOnDelete = () => {
        setWarning('');
        if (fmpConfig && fmpConfig.featureConfigData !== '') {
            submitRequest(deleteFeatureConfigRequestType, {entityId: data.id, entityType: 'FMP', entityTypeKey: 'PARTNER', token: userData.accessToken.jwtToken}, newRequest());
        }
        submitRequest(deleteRequestType, fields, newRequest());
        setShowNotification(true);
    }

    const PartnerUpdated = () => {
        if (showNotification && partnerUpdated && !requestInProgress) {
            return <Alert severity="success">Partner updated</Alert>
        }
        return null;
    }

    const PartnerDeleted = () => {
        if (showNotification && partnerDeleted && !requestInProgress) {
            return <Alert severity="success">Partner deleted</Alert>
        }
        return null;
    }

    const getFmpConfig = () => {
        submitRequest(getFeatureConfigRequestType, {entityId: data.id, entityType: 'FMP', entityTypeKey: 'PARTNER', token: userData.accessToken.jwtToken}, newRequest());
    }

    const handleSaveFmpOptions = () => {
        switch (fmpConfigEdited.strategy) {
            case 'AUTH_AND_REVERSE':
            case 'AUTH_AND_SAVE':
                if (mustUpdateFeatureConfig()) {
                    submitRequest(updateFeatureConfigRequestType, {entityId: data.id, entityType: 'FMP', entityTypeKey: 'PARTNER', plainEntityData: fmpConfigEdited, token: userData.accessToken.jwtToken}, newRequest());
                } else {
                    submitRequest(createFeatureConfigRequestType, {entityId: data.id, entityType: 'FMP', entityTypeKey: 'PARTNER', plainEntityData: fmpConfigEdited, token: userData.accessToken.jwtToken}, newRequest());
                }
                break;

            case 'INHERIT_FROM_PARENT':
                if (fmpConfig && fmpConfig.featureConfigData !== '') {
                    submitRequest(deleteFeatureConfigRequestType, {entityId: data.id, entityType: 'FMP', entityTypeKey: 'PARTNER', token: userData.accessToken.jwtToken}, newRequest());
                }
                break;
            default:
                return;
        }
    }

    const mustUpdateFeatureConfig = () => {
        return (fmpConfig && fmpConfig.featureConfigData !== '');
    }

    const onFmpOptionsChange = (updatedFmpOptions) => {
        setShowNotification(false);
        setFmpConfigEdited(updatedFmpOptions);
    }

    const Warning = () => {
        if (warning) {
            return <Alert severity="warning">{warning}</Alert>
        }
        return null;
    }

    return (
        <Dialog
            PaperComponent={DraggableDialog}
            aria-labelledby="draggable-dialog-title"
            open={true}
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                Edit Partner <CopyToClipboard text={data.id}>
                    <Chip label={data.id} size="small" color="primary"/>
                </CopyToClipboard>
            </DialogTitle>
            <DialogContent dividers={true}>
                {fieldList.map((name) => (
                    <FormInput
                        disabled={partnerDeleted}
                        key={name}
                        name={name}
                        onChange={handleOnChange}
                        getEditValue={name => fields[name]}
                        formFields={formFields}
                    />
                ))}
                <FmpOptions disabled={requestInProgress || partnerDeleted} onFmpOptionsChange={onFmpOptionsChange} value={fmpConfig.featureConfigData}/>
                <ProductPlanList
                    supportedPlans={supportedPlans}
                    onRemove={handlePlanRemove}
                    onAdd={handlePlanAdd}
                    onUpdate={handlePlanUpdate}
                    onUndo={handlePlanUndo}
                />
            </DialogContent>
            <div style={{paddingTop: "10px", paddingLeft: "10px", paddingRight: "10px", paddingBottom: "5px"}}>
                <ErrorMessage errorMessage={requestError}/>
                <Progress show={requestInProgress}/>
                <PartnerUpdated/>
                <PartnerDeleted/>
                <Warning/>
            </div>
            <DialogActions>
                <Button disabled={requestInProgress || partnerDeleted} variant="contained" color="secondary" onClick={handleOnDelete}>Delete</Button>
                <Button disabled={requestInProgress || partnerDeleted} variant="contained" color="primary" onClick={handleOnSave}>Save</Button>
                <Button disabled={requestInProgress} variant="contained" onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}