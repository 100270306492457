import React from "react";

import Grid from "@material-ui/core/Grid";

import ExPanel from "./ExPanel";
import ErrorMessage from "./ErrorMessage";
import useApiRequest from "../hooks/useApiRequest";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import SubmitButton from "./SubmitButton";
import Alert from "./Alert";
import {useSelector} from "react-redux";

export default function UpdatePayoutsStatusPanel({submitRequest}) {
    const payoutsStatusUpdate = useSelector(state => state.console.payoutsStatusUpdate);
    const [payoutIds, setPayoutIds] = React.useState('');
    const [, , requestError, newRequest] = useApiRequest();
    const [status, setStatus] = React.useState('PENDING');

    const handleUpdatePayoutStatusRequest = () => {
        if (payoutIds) {
            const payoutIdsArray = payoutIds.split(',');
            submitRequest('update-payouts-status', {payoutIds: payoutIdsArray, newStatus: status}, newRequest());
        }
    }

    const PayoutsStatusInfo = () => {
        if(!requestError && payoutsStatusUpdate) {
            return <Alert severity='info'>{payoutsStatusUpdate.successMessage}</Alert>
        }
        return null;
    }

    return (
        <ExPanel title="Update Payouts To Status">
            <Grid item xs={12}>
                <form>
                    <TextField
                        label="Payout IDs (comma separated)"
                        variant="outlined"
                        margin="normal"
                        size="small"
                        required
                        fullWidth
                        value={payoutIds}
                        onChange={e => {setPayoutIds(e.target.value);}}
                    />
                    <FormControl variant="outlined">
                        <InputLabel id="select-status-label">Status</InputLabel>
                        <Select
                            labelId="select-status-label"
                            id="select-status"
                            value={status}
                            margin="dense"
                            label="New Status"
                            onChange={e => {setStatus(e.target.value)}}
                        >
                            <MenuItem value={"PENDING"}>PENDING</MenuItem>
                            <MenuItem value={"OFFER_AVAILABLE"}>OFFER_AVAILABLE</MenuItem>
                            <MenuItem value={"COUNTER_OFFER_AVAILABLE"}>COUNTER_OFFER_AVAILABLE</MenuItem>
                            <MenuItem value={"DOCUMENTS_SIGNED"}>DOCUMENTS_SIGNED</MenuItem>
                            <MenuItem value={"OFFER_CONVERTED"}>OFFER_CONVERTED</MenuItem>
                            <MenuItem value={"DECLINED"}>DECLINED</MenuItem>
                            <MenuItem value={"CANCELED"}>CANCELED</MenuItem>
                            <MenuItem value={"EXPIRED"}>EXPIRED</MenuItem>
                            <MenuItem value={"SETTLED"}>SETTLED</MenuItem>
                        </Select>
                    </FormControl>
                    <SubmitButton onClick={handleUpdatePayoutStatusRequest}/>
                    <ErrorMessage errorMessage={requestError}/>
                    <PayoutsStatusInfo/>
                </form>
            </Grid>
        </ExPanel>
    )
}
