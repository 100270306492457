import React, {Component} from "react";
import {connect} from "react-redux";

import {HashRouter, Route, Switch} from "react-router-dom";
import DashboardPage from "./views/DashboardPage";
import PartnersPage from "./views/PartnersPage";
import MerchantsPage from "./views/MerchantsPage";
import LoansPage from "./views/LoansPage";
import VaultPage from "./views/VaultPage";
import SettingsPage from "./views/SettingsPage";
import DocumentsPage from "./views/DocumentsPage";
import PaymentsPage from "./views/PaymentsPage";
import UsersPage from "./views/UsersPage";
import DataLakePage from "./views/DataLakePage";
import CloudWatchPage from "./views/CloudWatchPage";
import SplunkPage from "./views/SplunkPage";
import DynamoDBPage from "./views/DynamoDBPage";
import TemplatesPage from "./views/TemplatesPage";
import TemplatePreviewPage from "./views/TemplatePreviewPage";
import {getSettings, submitIpcRequest} from "./store/actions/consoleActions";
import {getIpcRenderer} from "./utils/electron";
import axios from "axios";

axios.defaults.headers.common['x-wisetack-aud'] = 'WTC';

const ipcRenderer = getIpcRenderer();

class App extends Component {

  componentDidMount() {
    if (ipcRenderer) {
      this.loadData()
      ipcRenderer.on("authorized",(e, data) => {
        console.log("authorized", data)
        this.loadData()
      })
    }
  }

  loadData = () => {
    this.props.getSettings();
    this.props.submitIpcRequest(null, {requestTypeList: ['metadata', 'audit-meta', 'partners-info', 'recent-partner-merchants', 'recent-loans', 'partner-allocation-configs']})
  }

  render() {
    return (
      <HashRouter>
        <div>
          <Switch>
            <Route exact path="/" component={DashboardPage} />
            <Route exact path="/partners" component={PartnersPage} />
            <Route exact path="/merchants" component={MerchantsPage} />
            <Route exact path="/loans" component={LoansPage} />
            <Route exact path="/documents" component={DocumentsPage} />
            <Route exact path="/users" component={UsersPage} />
            <Route exact path="/payments" component={PaymentsPage} />
            <Route exact path="/vault" component={VaultPage} />
            <Route exact path="/data_lake" component={DataLakePage} />
            <Route exact path="/cloud_watch" component={CloudWatchPage} />
            <Route exact path="/splunk" component={SplunkPage} />
            <Route exact path="/dynamodb" component={DynamoDBPage} />
            <Route exact path="/templates" component={TemplatesPage} />
            <Route exact path="/template_preview/:templateId" component={TemplatePreviewPage} />
            <Route exact path="/settings" component={SettingsPage} />
          </Switch>
        </div>
      </HashRouter>
    );
  }
}

export default connect(
    null,
    { getSettings, submitIpcRequest }
)(App);

