import 'date-fns';
import React from 'react';

import Grid from "@material-ui/core/Grid";

import ExPanel from "./ExPanel";
import Box from "@material-ui/core/Box";
import {Button} from "@material-ui/core";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {makeStyles} from "@material-ui/core/styles";
import useApiRequest from "../hooks/useApiRequest";
import {CloudDownload} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    input: {
        display: 'none',
    },
}));

export default function CitizensBalanceIDPanel({submitRequest}) {
    const classes = useStyles();
    const [, , , newRequest] = useApiRequest();

    function uploadFile(event) {
        const file = event.target.files[0];
        const uploadData = {
            bucket: 'capitalpartnerinbox',
            filename: `citizens/${file.name}`,
            data: file
        };
        submitRequest('upload-to-s3', uploadData, newRequest());
    }

    function handleDownload(event) {
        event.preventDefault();
        const requestData = {
            fileAction: 'DOWNLOAD',
            partnerBank: `CITIZENS`
        };
        submitRequest('download-disbursement-files', requestData, newRequest());
    }

    return (
        <ExPanel title="Citizens BalanaceID">
                <Grid item xs={12}>
                    <Box sx={{ margin: 5 }}>
                        <form className={classes.root}>
                            <input
                                className={classes.input}
                                id="contained-button-file"
                                type="file"
                                accept=".txt"
                                onChange={uploadFile}
                            />

                            <label htmlFor="contained-button-file">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    component="span"
                                    startIcon={<CloudUploadIcon />}
                                >
                                    Upload File
                                </Button>
                            </label>
                        </form>
                    </Box>
                    <Box sx={{ margin: 5 }}>
                        <label htmlFor="contained-button-file">
                            <Button
                                variant="contained"
                                color="primary"
                                component="span"
                                startIcon={<CloudDownload />}
                                onClick={handleDownload}
                            >
                                Download Files
                            </Button>
                        </label>
                    </Box>
                </Grid>
        </ExPanel>
    )
}
