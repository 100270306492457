import React from 'react';
import Panel from "./Panel";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import TextsmsIcon from '@material-ui/icons/Textsms';
import EmailIcon from '@material-ui/icons/Email';
import {useHistory} from "react-router-dom";

export default function TemplatesList({templates, inProgress}) {

    const history = useHistory();

    const goToTemplateDetailView = (id) => {
        history.push({
            pathname: '/template_preview/' + id
        });
    }

    return (
        <Panel title="Templates List" height={600}>
            <List>
                {templates && templates.map((id) => (
                    <ListItem disabled={inProgress} key={id} button onClick={() => goToTemplateDetailView(id)}>
                        <ListItemIcon>
                            { id.toLowerCase().includes('email') ? <EmailIcon/> : <TextsmsIcon/> }
                        </ListItemIcon>
                        <ListItemText primary={id} />
                    </ListItem>
                ))}
            </List>
        </Panel>
    )
}