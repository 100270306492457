import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import DraggableDialog from "./DraggableDialog";
import useApiRequest from "../hooks/useApiRequest";
import {ficoFloorUpdateFormFields, ficoFloorUpdateFormFieldsList} from "../utils/constants";
import FormInput from "./FormInput";
import useFields from "../hooks/useFields";
import useApiRequestExecuted from "../hooks/useApiRequestExecuted";
import ErrorMessage from "./ErrorMessage";
import Progress from "./Progress";
import Alert from "./Alert";

const formFields = ficoFloorUpdateFormFields;
const fieldList = ficoFloorUpdateFormFieldsList;

export default function EditFicoFloorDialog({data, onClose, submitRequest}) {
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();
    const ficoScoreUpdated = useApiRequestExecuted('ficoFloorUpdateRequestId', requestId);
    const [fields, handleOnChange] = useFields(formFields, data);

    const FicoScoreUpdated = () => {
        if (ficoScoreUpdated) {
            return <Alert severity="success">FICO score updated</Alert>
        }
        return null;
    }

    const handleOnSave = () => {
        submitRequest('update-fico-floor', {...fields, currentValue: data.value}, newRequest())
    }

    return (
        <Dialog
            PaperComponent={DraggableDialog}
            aria-labelledby="draggable-dialog-title"
            open={true}
        >
            <DialogTitle style={{cursor: 'move'}} id="draggable-dialog-title">
                Edit score for vertical: <br/> {data.vertical}
            </DialogTitle>
            <DialogContent dividers={true}>
                    {fieldList.map((name) => (
                        <FormInput
                            key={name}
                            name={name}
                            formFields={formFields}
                            getEditValue={name => fields[name]}
                            onChange={handleOnChange}
                        />
                    ))}
            </DialogContent>
            <div style={{paddingTop: "10px", paddingLeft: "10px", paddingRight: "10px", paddingBottom: "5px"}}>
                <ErrorMessage errorMessage={requestError}/>
                <Progress show={requestInProgress}/>
                <FicoScoreUpdated/>
            </div>
            <DialogActions>
                <Button disabled={requestInProgress} variant="contained" color="primary" onClick={handleOnSave}>Save</Button>
                <Button disabled={requestInProgress} variant="contained" onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}