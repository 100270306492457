import React from 'react';

import Grid from "@material-ui/core/Grid";

import ExPanel from "./ExPanel";
import ReloadButton from "./ReloadButton";
import {isDisabled} from "../store/actions/aws";
import {DataGrid} from "@mui/x-data-grid";
import Chip from "@material-ui/core/Chip";
import {useSelector} from "react-redux";
import useApiRequest from "../hooks/useApiRequest";
import Progress from "./Progress";
import ErrorMessage from "./ErrorMessage";
import EditFicoFloorDialog from "./EditFicoFloorDialog";
import CreateFicoFloorDialog from "./CreateFicoFloorDialog";
import Button from "@material-ui/core/Button";


export default function FicoFloors({submitRequest}) {

    const ficoFloorsState = useSelector(state => state.console.ficoFloors);
    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest();
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openCreateDialog, setOpenCreateDialog] = React.useState(false);
    const [selectedRow, setSelectedRow] = React.useState('');

    const handleReloadRequest = () => {
        submitRequest('fico-floors-list', {}, newRequest());
    }

    const handleEdit = (row) => {
        setSelectedRow(row);
        setOpenDialog(true);
    }

    const handleEditClose = () => {
        setOpenDialog(false);
    }

    const handleOnSaveClick = (row) => {
        setOpenCreateDialog(true)
    }

    const verticalCellRenderer = ({row, value}) => {
        return <Chip
            label={value}
            size="small"
            color={"default"}
            onClick={() => {
                handleEdit(row)
            }}
        />
    }

    const columns = [
        {field: 'vertical', headerName: 'Vertical', width: 140, renderCell: verticalCellRenderer},
        {field: 'value', headerName: 'FICO Score Floor', width: 240},
    ]

    const FicoScoresGrid = () => {

        if (!ficoFloorsState) {
            return <div>Press reload to get data</div>;
        }

        return <div style={{height: 450, width: '100%'}}>
            <DataGrid
                getRowId={(row) => row.vertical}
                rowHeight={46}
                rowsPerPageOptions={[10]}
                pageSize={10}
                rows={ficoFloorsState}
                hideFooterSelectedRowCount={true}
                columns={columns}
                loading={requestInProgress}
                density='compact'
            />
        </div>
    }

    return (
        <ExPanel title="FICO Score Floors" hidden={isDisabled('fico-floors-list')}>
            <Grid item xs={12}>
                <div>Default value per vertical is 540 (if other not specified below)</div>
                <ReloadButton onClick={handleReloadRequest} disabled={requestInProgress}/>
                <Button
                    disabled={requestInProgress}
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={handleOnSaveClick}
                    style={{
                        marginBottom: '10px',
                        marginLeft: '10px'
                    }}
                >
                    Create
                </Button>
                <Progress show={requestInProgress} requestId={requestId}/>
                <ErrorMessage errorMessage={requestError}/>
                <FicoScoresGrid/>
                {openDialog && <EditFicoFloorDialog
                    data={selectedRow}
                    onClose={handleEditClose}
                    submitRequest={submitRequest}
                />}
                {openCreateDialog &&
                    <CreateFicoFloorDialog
                        onClose={() => setOpenCreateDialog(false)}
                        submitRequest={submitRequest}
                    />
                }
            </Grid>
        </ExPanel>
    )
}
